<app-container title="{{'Demographics'|translate}}">

  <app-content>
    <dx-data-grid
    [dataSource]="dataSource"
    [showBorders]="true"
    [focusedRowEnabled]="true"
    [focusedRowKey]="focusedRowKey"
    [focusedRowIndex]="0"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="false"
    keyExpr="demographicUuid"
    noDataText="{{'No data' | translate}}"
    (onContentReady)="dataGridOnContentReady($event)"
    (onEditCanceled)="onEditCanceled($event)"
    (onEditingStart)="onEditingStart($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)"
    (onRowRemoved)="onRowRemoved($event)"
    (onRowValidating)="onRowValidating($event)"
    (onSaved)="onSaved($event)"
    (onSaving)="onSaving($event)"
    [rowAlternationEnabled]="true"
  >

    <dxo-editing mode="form"
      [allowAdding]="false | canAddPermission : entityArea"
      [allowDeleting]="checkDeleteAccess"
      [allowUpdating]="checkUpdateAccess"
      [selectTextOnEditStart]="true"
      [texts]="editingTexts"
      [useIcons]="true"
      >
      <dxo-form validationGroup="valGroup" colCount="1" [scrollingEnabled]="true">
        <dxi-item itemType="tabbed">
          <dxi-tab title="{{'General'|translate}}">
            <dxi-item dataField="description">
              <dxi-validation-rule message="{{'Name may not be null or empty.'|translate}}" type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="isGlobal" [editorType]="'dxCheckBox'"></dxi-item>
            <dxi-item dataField="startDate" [editorOptions]="{ disabled: true, showDropDownButton: false }"></dxi-item>
            <dxi-item dataField="endDate" [editorOptions]="{ disabled: true, showDropDownButton: false }"></dxi-item>
          </dxi-tab>

        <dxi-tab title="{{'Variables'|translate}}">
          <dxi-item name="variables">
            <div *dxTemplate>
              <dx-filter-builder 
                [customOperations]="customOperations"
                [fields]="variables"
                [groupOperationDescriptions]="groupOperationDescriptions"
                [groupOperations]="['and', 'or']"
                [(value)]="filterModel"
                (onValueChanged)="dxFilterBuilder_onValueChange($event)">

                <dxi-field
                  #field
                  *ngFor="let item of variables; trackBy: trackFilterVariables"
                  [editorOptions]="{data: item, searchEnabled: true}"
                  [editorTemplate]="'betweenTemplate'"
                  [filterOperations]="['contains']" 
                  [dataField]="item.description"                
                  [allowSorting]="true"
                  [allowFiltering]="true"
                  [wordWrapEnabled]="false"
                  dataType="object">
                  <div *dxTemplate="let condition of 'betweenTemplate'">
                    <app-multi-select-tree-view-drop-down
                      [(dropDownSelection)]="userSelectedFilterValuesTreeView"
                      (dropDownSelectionChange)="demoChkValueSelect_checked($event, condition)"
                      (onInitialized)="onInitialised(condition)"
                      [(opened)]="isDropDownOpened"
                      [focusedRowEnabled]="false"
                      [condition]="condition"
                      [dataSource]="filterDropDownValuesTreeView"                  
                      [enableRequiredValidation]="true"
                      requiredValidationMessage="{{'Value required'|translate}}"
                    >
                    </app-multi-select-tree-view-drop-down>
                  </div>
                </dxi-field>
              </dx-filter-builder>

              </div>
            </dxi-item>

          </dxi-tab>
        </dxi-item>
      </dxo-form>
    </dxo-editing>

  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager infoText="{{'Page {0} of {1} ({2} items)'| translate}}" [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row betweenEndText="{{'End'|translate}}" betweenStartText="{{'Start' | translate}}" [operationDescriptions]="operationDescriptions" resetOperationText="{{'Reset' | translate}}" [visible]="true"></dxo-filter-row>
  <dxo-sorting ascendingText="{{'Sort Ascending' | translate}}" clearText="{{'Clear Sorting'|translate}}" descendingText="{{'Sort Descending'|translate}}"></dxo-sorting>

  <dxi-column
    dataField="description"
    caption="{{'Name'|translate}}"
    sortOrder="asc">
  </dxi-column>
  <dxi-column
    dataField="organizationId"
    caption="{{'Company'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="isGlobal"
    caption="{{'Global'|translate}}"
    falseText="{{'false' | translate}}"
    trueText="{{'true' | translate}}">
  </dxi-column>
  <dxi-column
    dataField="startDate"
    caption="{{'Start Date'|translate}}"
    dataType="date"
    format="dd/MM/yyyy">
  </dxi-column>
  <dxi-column
    dataField="endDate"
    caption="{{'End Date'|translate}}"
    dataType="date"
    format="dd/MM/yyyy">
  </dxi-column>
</dx-data-grid>
</app-content>
</app-container>