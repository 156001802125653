import { Pipe, PipeTransform } from '@angular/core';
import { EntityAction } from '../enums/entity-action.enum';
import { EntityArea } from '../enums/entity-area.enum';
import { AuthorizationService } from '../services/authorization.service';

@Pipe({
  name: 'canAddPermission'
})
export class CanAddPermissionPipe implements PipeTransform {

  constructor(private authService : AuthorizationService){
  }

  transform(value: any, area: EntityArea ): boolean {
    return this.authService.hasAccess(area, EntityAction.Create);
  }

}
