import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { DemographicLookupModel } from 'src/app/core/models/demographic/demographic-lookup-model';
import { DropDownModel } from 'src/app/core/models/ui/drop-down-model';
import { TreeViewModel } from 'src/app/core/models/ui/tree-view-model';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { DemographicsService } from 'src/app/core/services/demographics/demographics.service';
import { MultiSelectTreeViewDropDownComponent } from 'src/app/shared/components/multi-select-tree-view-drop-down/multi-select-tree-view-drop-down.component';

@Component({
  selector: 'app-demographics-multi-select-lookup',
  templateUrl: './demographics-multi-select-lookup.component.html',
  styleUrls: ['./demographics-multi-select-lookup.component.scss']
})
export class DemographicsMultiSelectLookupComponent implements OnInit, OnDestroy {

  @ViewChild(MultiSelectTreeViewDropDownComponent, { static: false}) dropDown: MultiSelectTreeViewDropDownComponent;

  unsubscribe$ = new Subject<boolean>();
  demographicLookUp = new Array<DropDownModel>();
  @Input() selectedDemographics: TreeViewModel[];
  @Output() selectedDemographicsChange = new EventEmitter<TreeViewModel[]>();

  constructor(private demographicService: DemographicsService, private appService: AppConstantService) { }

  ngOnInit() {
    this.loadDemographics();
  }

  loadDemographics() {
    this.demographicService.get<ApiResponse<DemographicLookupModel[]>>(this.appService.apiPath.demographicLookups)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(s => {
          this.onDemographicGetSuccess(s.data.entity)
        }
    )
  }

  onDemographicGetSuccess(demographics: DemographicLookupModel[]) {
    this.demographicLookUp = demographics.map(d => ({id: d.demographicUuid, name: d.description, isChecked: false}));
  }

  onDemographicChanged(eventArgs: TreeViewModel[]) {
    this.selectedDemographics = eventArgs;
    this.selectedDemographicsChange.emit(this.selectedDemographics);
  }

  validate(): boolean {
    this.dropDown.revalidate();
    
    return !this.dropDown.showValidationMessage;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }
}
