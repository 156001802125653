import { Injectable, ErrorHandler } from '@angular/core';
import { NotifyService } from 'src/app/core/services/notify.service';
import { environment } from 'src/environments/environment';
import { NotifyType } from '../enums/notify-type.enum';


@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private notify: NotifyService
           ) { }
    handleError(error) {
    // this.notify.showNotification(error, NotifyType.Error )
    const errorMessage = `${error.message} , Please refer to the console log for more detail`;
    if (!environment.production) {
      this.notify.showNotification(errorMessage, NotifyType.Error, 'Error', 6000);
    }
     console.error(error);
     // IMPORTANT: Rethrow the error otherwise it gets swallowed
     throw error;
  }
}
