import { UserRole } from '../../enums/user-role.enum';
import { BaseModel } from '../base-model';
import { CurrentUser } from './get-token-response.model';
import { UserResourceModel } from './user-resource.model';

export class UserModel extends BaseModel {
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  companyUuid: string;
  role: UserRole;
  status: string;
  organizationId: string;
  roleIds?: string[];
  providerIds?: string[];
  resources?: UserResourceModel[];
  exp: number;
  isAdmin: boolean;

  constructor(currentUser: CurrentUser, exp: Date | null) {
    super();
    const user = currentUser.user;
    const organizationId = currentUser.currentOrganizationId;
    const roleIds = currentUser.currentRoleIds;
    const resources = currentUser.currentResources.filter((r) =>
      r.resourceId.includes('AquiraPlannerAU')
    );
    const providerIds = currentUser.currentProviders.map((p) => p.providerId);
    const dateTimestamp = exp !== null ? new Date(exp).getTime() : null;
    const isAdmin = currentUser.currentOrganizationAdministrator;

    this.email = user.email;
    this.userId = user.id;
    this.companyUuid = organizationId;
    this.organizationId = organizationId;
    this.roleIds = roleIds;
    this.providerIds = providerIds;
    this.resources = resources;
    this.exp = dateTimestamp;
    this.isAdmin = isAdmin;
  }
}
