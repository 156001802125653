import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpResult } from '../../helpers/http-result';
import { DemographicLookupModel } from '../../models/demographic/demographic-lookup-model';
import { DemographicModel } from '../../models/demographic/demographic-model';
import { AppConstantService } from '../app-constant.service';
import { HttpService } from '../http.service';
import { ApiResponse } from '../../models/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class DemographicsService extends HttpService {
  constructor(private appService: AppConstantService) {
    super();
  }

  insert(
    demographic: DemographicModel
  ): Observable<HttpResult<ApiResponse<DemographicModel>>> {
    return this.post<ApiResponse<DemographicModel>>(
      this.appService.apiPath.demographics,
      demographic
    );
  }

  getDemographicLookup(): Observable<
    HttpResult<ApiResponse<DemographicLookupModel[]>>
  > {
    return this.get<ApiResponse<DemographicLookupModel[]>>(
      this.appService.apiPath.demographicLookups
    );
  }

  getDemographicByUuid(uuid: string) {
    let requestUrl = `Demographics/${uuid}`;
    return this.get<ApiResponse<DemographicModel>>(requestUrl);
  }

  update(
    demographic: DemographicModel
  ): Observable<HttpResult<ApiResponse<DemographicModel>>> {
    return this.put<ApiResponse<DemographicModel>>(
      this.appService.apiPath.demographics,
      demographic
    );
  }
}
