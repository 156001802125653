import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  add(key: string, item: any): void {
    window.sessionStorage.setItem(key, JSON.stringify(item));
  }

  get(key: string): any {
    return JSON.parse(window.sessionStorage.getItem(key));
  }

  remove(key: string) {
    if (window.sessionStorage.getItem(key)) {
      window.sessionStorage.removeItem(key);
    }
  }

  update(key: string, item: any): void {
    if (window.sessionStorage.getItem(key)) {
    window.sessionStorage.setItem(key, JSON.stringify(item));
    }
  }

  exists(key: string) {
    if (window.sessionStorage.getItem(key)) {
      return true;
    }
    return false;
  }

  clear() {
    window.sessionStorage.clear();
  }
}
