<ng-content></ng-content>

<div class="menu-container dx-theme-accent-as-background-color">
  <dx-tooltip [target]="target" [(visible)]="isVisible">  
    <div *dxTemplate="let data = data of 'content'">  
      <span>{{tooltipContent}}</span>  
    </div>  
</dx-tooltip>  
 
<div id="treeViewSideMenu">
  <dx-tree-view 
    [items]="items"
    keyExpr="path"
    selectionMode="single"
    [focusStateEnabled]="false"
    (onContentReady)="onContentReady($event)"
    expandEvent="click"
    (onItemClick)="onItemClick($event)"     
    width="100%">    
  </dx-tree-view>
</div>
</div>

