import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injector, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule, DoubleCardModule } from './layouts';
import { FooterModule, LoginCallbackComponent, LoginFormComponent} from './shared/components';
import { ScreenService, AppInfoService, TranslationService } from './shared/services';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { GlobalErrorHandlerService } from './core/services/global-error-handler.service';
import { AppInjectorService } from './core/services/app-injector.service';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { HttpBackend, HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './core/services/http-interceptor.service';
import { AuthorizationService } from './core/services/authorization.service';
import { CompaniesComponent } from './pages/companies/companies.component';
import { HomeComponent } from './pages/home/home.component';
import { MarketsComponent } from './pages/markets/markets.component';
import { PlacesComponent } from './pages/places/places.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { UsersComponent } from './pages/users/users.component';
import { VariablesComponent } from './pages/variables/variables.component';
import { DxDataGridModule, DxCheckBoxModule, DxFormModule, DxLoadPanelModule, DxDropDownBoxModule, DxListModule, DxFileUploaderModule, DxButtonModule, DxTooltipModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { DemographicsModule } from './pages/demographics/demographics.module';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    CompaniesComponent,
    MarketsComponent,
    //MultiSelectDropDownComponent,
    PlacesComponent,
    SurveysComponent,
    UsersComponent,
    VariablesComponent,
    LoginFormComponent,
    LoginCallbackComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    DoubleCardModule,
    FooterModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    DxTabPanelModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxFormModule,
    DxLoadPanelModule,
    DxDropDownBoxModule,
    DxListModule,
    DxFileUploaderModule,
    DxLoadIndicatorModule,
    FormsModule,
    DemographicsModule,
    DxTooltipModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend]
      }
  })
  ],
  providers: [AuthorizationService,
              ScreenService,
              AppInfoService,
              { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
              { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
             // {provide: LocationStrategy, useClass: HashLocationStrategy},
              AppInjectorService, TranslateService
            ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(injector: Injector){
    AppInjectorService.setCurrentInjector(injector);
  }

 }
export function HttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend), "./assets/i18n/");
}
