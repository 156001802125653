<dx-data-grid
#gridView
[class]="cssClass"
[dataSource]="dataSource"
[columns]="gridColumns"
[columnAutoWidth]="columnAutoWidth"
[columnMinWidth]="70"
[errorRowEnabled]="errorRowEnabled"
[focusedRowEnabled]="focusedRowEnabled"
[focusedRowIndex]="focusedRowIndex"
[hoverStateEnabled]="true"
[keyExpr]="keyExpr"
[allowColumnReordering]="true"
[allowColumnResizing]="true"
fixed="true"
[showColumnLines]="true"
[showBorders]="true"
[loadPanel]="{enabled:true, text: 'Loading...'|translate}"
noDataText="{{'No data' | translate}}"
[editing]="editing"
[columnHidingEnabled]="columnHidingEnabled"
[rowAlternationEnabled]="true"
(onInitialized)="onGridInitialized($event)"
(onContentReady)="onGridContentReady($event)"
(onEditCanceled)="onGridEditCanceled($event)"
(onEditingStart)="onGridEditingStart($event)"
(onEditorPreparing)="onGridEditorPreparing($event)"
(onInitNewRow)="onGridInitNewRow($event)"
(onRowRemoved)="onGridRowRemoved($event)"
(onSaved)="onGridSaved($event)"
(onSaving)="onGridSaving($event)"
>
<dxo-grouping [contextMenuEnabled]="true" [texts]="groupingTexts"></dxo-grouping>
<dxo-group-panel emptyPanelText="{{'Drag a column header here to group by that column'|translate}}" [visible]="groupPanel"></dxo-group-panel>
<dxo-filter-row [operationDescriptions]="operationDescriptions" resetOperationText="{{'Reset' | translate}}" [visible]="true"></dxo-filter-row>
<dxo-selection mode="single"></dxo-selection>
<dxo-column-chooser [enabled]="columnChooser" mode="select" title="{{'Column Chooser'|translate}}"> <!-- or "select" --> </dxo-column-chooser>
<dxo-export [enabled]="export" [fileName]="fileName" [texts]="{exportAll: 'Export all data'|translate}"></dxo-export>
<dxo-scrolling [showScrollbar]="true" [mode]="'standard'"></dxo-scrolling>
<dxo-paging [enabled]="true"  [pageSize]="pageSize" [pageIndex]="0"  ></dxo-paging>
<dxo-pager  [visible]="true" [showInfo]="true"  [showNavigationButtons]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 50, 100]" infoText="{{'Page {0} of {1} ({2} items)'| translate}}">
</dxo-pager>
</dx-data-grid>
