import { DemographicFilterGroupModel } from 'src/app/core/models/demographic/demographic-filter-group-model';
import { DemographicFilterVariableModel } from 'src/app/core/models/demographic/demographic-filter-variable-model';
import { VariableModel } from 'src/app/core/models/variable-model';
import { DemographicFilterModel } from 'src/app/core/models/demographic/demographic-filter-model';
import { DemographicFilterJoinType } from 'src/app/core/enums/demographic-filter-join-type.enum';

export class WebApiFilterModelBuilder {

  constructor(private variables: VariableModel[]) {

  }

  getFilterBuilderValues(dxModel: any[]): DemographicFilterGroupModel {
    if (!dxModel || !(dxModel.length > 0)) {
      dxModel = ['and'];
    }
    return this.getDxFilterBuilderValues(dxModel);
  }

  // getFilterBuilderVariables(dxModel: any[]) {

  //   let filterVariables = new Array<VariableModel>();

  //    const populateFilterVariables = (groupArray: any[]) => {
  //        const filterItems = groupArray.filter(f => this.isFilterLine(f));
  //        const filterGroups = groupArray.filter(f => this.isGroup(f));

  //        if (filterItems && filterItems.length > 0) {
  //            this.getFilterVariableModel(filterItems)
  //                .forEach(variable => filterVariables.push(variable));
  //        }

  //        if (filterGroups && filterGroups.length > 0) {
  //          filterGroups.forEach( filterGroup => {
  //            populateFilterVariables(filterGroup);
  //          });
  //        }
  //    };
  //    populateFilterVariables(dxModel);
  //    filterVariables = filterVariables.filter((elem, index, arr) => arr && arr.findIndex(f => f && (f.id === elem.id)) === index);
  //    return filterVariables;
  //   }

  private getDxFilterBuilderValues(dxModel: any[]): DemographicFilterGroupModel {
    const result = new DemographicFilterGroupModel();
    if (dxModel && dxModel.length > 0) {
      const filterItems = dxModel.filter(f => this.isFilterLine(f));
      const filters = dxModel.filter(f => this.isGroup(f));
      const operator = this.getOperations(dxModel);

      const filtersModel = new Array<DemographicFilterModel>();

      if (operator) {
        result.joinType = operator;
      }

      if (filterItems && filterItems.length > 0) {
        const varVals = this.mapFilteritems(filterItems);

        varVals.forEach(v => {
          filtersModel.push(v);
        });
      }

       if (filters && filters.length > 0) {
         filters.forEach( v => {
          const filterGroup = this.getDxFilterBuilderValues(v);
          filtersModel.push(filterGroup);
         });
       }
       result.filters = filtersModel;
    }

    return result;
  }

  private isFilterLine(filter: any) {
    if (this.getOperations(filter) || !Array.isArray(filter)) {
      return false;
    }
    return true;
  }

  private isGroup(group: any) {
    if (Array.isArray(group) && group.length > 0) {
      const operator = group.includes('and') || group.includes('or');
      if (operator) {
        return true;
      }
    }
    return false;
  }

  private mapFilteritems(filters: any[]) {
    const filterItems = new Array<DemographicFilterVariableModel>();
    if (filters) {
    filters.forEach(v =>  {
      const item = new DemographicFilterVariableModel();
      const variableId = this.getVariable(v[0]).variableId;
      item.variableId = variableId;

      item.valueIds = this.getSelectedValues(v[2], variableId);
      filterItems.push(item);
    });
  }

    return filterItems;
  }

  // private getFilterVariableModel(filters: any[]): VariableModel[] {
  //   const variableModels = new Array<VariableModel>();
  //   if (filters) {
  //   filters.forEach(v =>  {
  //     let variableModel = new VariableModel();
  //     variableModel = this.variables.find(f => f.name === v[0]);
  //     variableModels.push(variableModel);
  //   });
  // }
  //   return variableModels;
  // }

  private getOperations(arr: any): DemographicFilterJoinType {
    if (arr && Array.isArray(arr)) {
      if (arr.includes('and') || arr.includes('or')) {
        const  operation = arr.find(f => (f === 'and' || f === 'or'));
        return this.getOperationEnum(operation);
      }
    }
    return undefined;
  }


  private getSelectedValues(selectedValues: string[], variableId: number) {
  const result = new Array<number>();
  const valueArray = new Array<any>();

  if (selectedValues) {
    selectedValues.forEach(v => {
      const val = this.variables.find(v=>v.variableId === variableId)?.values.find(value => value.description === v);
      result.push(val.valueId);
      valueArray.push(val);
    });
  }

  return result;
}

private getVariable(variableName: string) {
return this.variables.find(f => f.description === variableName);
}


private getOperationEnum(operation: string) {

  if (operation === 'and') {
    return DemographicFilterJoinType.And;
  } else
  if (operation === 'or') {
    return DemographicFilterJoinType.Or;
  }
}

}