import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { PlaceModel } from 'src/app/core/models/place-model';
import { DropDownModel } from 'src/app/core/models/ui/drop-down-model';
import { TreeViewModel } from 'src/app/core/models/ui/tree-view-model';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { PlacesService } from 'src/app/core/services/place/places.service';
import { MultiSelectTreeViewDropDownComponent } from 'src/app/shared/components/multi-select-tree-view-drop-down/multi-select-tree-view-drop-down.component';

@Component({
  selector: 'app-place-look-up',
  templateUrl: './place-look-up.component.html',
  styleUrls: ['./place-look-up.component.scss']
})
export class PlaceLookUpComponent implements OnInit, OnDestroy {

  @ViewChild(MultiSelectTreeViewDropDownComponent, { static: false}) dropDown: MultiSelectTreeViewDropDownComponent;

  unsubscribe$ = new Subject<boolean>();
  placeLookUp = new Array<DropDownModel>();
  @Input() selectedPlaces: TreeViewModel[];
  @Output() selectedPlacesChange = new EventEmitter<TreeViewModel[]>();

  constructor(private placesService: PlacesService,
    private appService: AppConstantService) { }

  ngOnInit() {
    this.loadPlace();
  }

  loadPlace() {
    this.placesService.get<ApiResponse<PlaceModel[]>>(this.appService.apiPath.places)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(s => {
        this.onPlacesGetSucess(s.data.entity)
      })
  }

  onPlacesGetSucess(placeModel: PlaceModel[]) {
    this.placeLookUp = placeModel.map(m => ({id: m.placeId, name: m.placeDesc, isChecked: false}));
  }

  placesChanged(eventArgs) {
    this.selectedPlaces = eventArgs;
    this.selectedPlacesChange.emit(this.selectedPlaces);
  }

  validate(): boolean {
    this.dropDown.revalidate();

    return !this.dropDown.showValidationMessage;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
  }
}
