import { Component, NgModule, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { DxTreeViewModule, DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import { navigation } from '../../../app-navigation';

import * as events from 'devextreme/events';
import { TranslateService } from '@ngx-translate/core';
import { DxTooltipModule } from 'devextreme-angular';
import { dxTreeViewNode } from 'devextreme/ui/tree_view';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { UserModel } from 'src/app/core/models/user/user-model';
import { EntityAction } from 'src/app/core/enums/entity-action.enum';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy, OnInit {

tooltipContent: string;  
target: any;  
isVisible: boolean = false;  
treeviewNodes: dxTreeViewNode[];
user: UserModel;

  @ViewChild(DxTreeViewComponent, { static: true })
  menu: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<string>();

  @Output()
  openMenu = new EventEmitter<any>();

  private _selectedItem: String;
  @Input()
  set selectedItem(value: String) {
    this._selectedItem = value;
    if (!this.menu.instance) {
      return;
    }

    this.menu.instance.selectItem(value);
  }

  private _items;
  get items() {
    if (!this._items) {
      this._items = navigation.map((item) => {
        if(item.path && !(/^\//.test(item.path))){ 
          item.path = `/${item.path}`;
        }
         return { ...item, expanded: !this._compactMode }
        });

      this._items.forEach((item)=>{
          this.translate.get(item.text).subscribe((result)=>{
            item.text = result;
          });
          /**if 'area' exists then we must test access */
          if(item.area){
            item.visible = this.authService.hasAccess(item.area,EntityAction.Read);
          }
        });
    }

    return this._items;
  }

  private _compactMode = false;
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  constructor(private elementRef: ElementRef, 
              private translate: TranslateService, 
              private authService: AuthorizationService) { }
  
  ngOnInit(): void {
    if(this.authService.isAuthenticated()){
      this.user = this.authService.userSession.userModel;
    }
  }

  onItemClick(event) {
    this.selectedItemChanged.emit(event);
  }

  onTreeViewItemIconMouseEnter = (menuItem, event) => {
    console.log('eventParams', [menuItem, event]);
    this.tooltipContent = menuItem.text;
    this.target = event.target;
    this.isVisible = true;  
  }
  
  onTreeViewItemMouseLeave = (eventParam) => {
    this.isVisible = false;
  }

  onTreeListItemMouseEnter = (event, index) => {
    this.treeviewNodes.findIndex((value, i) =>  {
      if (index === i) {
        this.tooltipContent = value.text;
      }
    })
    this.target = event.target;  
    this.isVisible = true;  
}
  

  onTreeListItemMouseLeave = () => {
    this.isVisible = false;
  }

  onContentReady(e: any) {  
    this.treeviewNodes = this.menu.instance.getNodes(); 
    let htmlElements = this.menu.instance.element();
    if (!htmlElements.length) return;
    let elements = htmlElements[0].querySelectorAll("#treeViewSideMenu .dx-treeview-item .dx-icon");      
    elements.forEach((el, index)  => { 
        el.addEventListener("mouseenter", event => { this.onTreeListItemMouseEnter(event, index)} ); 
        el.addEventListener("mouseleave", event => {this.onTreeListItemMouseLeave()} );  
    });  
  }  

  ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (e) => {
      this.openMenu.next(e);
    });
  }

  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
  }
}

@NgModule({
  imports: [ DxTreeViewModule, DxTooltipModule ],
  declarations: [ SideNavigationMenuComponent ],
  exports: [ SideNavigationMenuComponent ]
})
export class SideNavigationMenuModule { }
