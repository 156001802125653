import { Injectable } from '@angular/core';
import { UserModel } from '../models/user/user-model';
import { UserSession } from '../models/user/user-session';
import { AllowedActionsModel } from '../models/allowed-actions-model';
import { AppConstants } from 'src/app/app-constants';
import { SessionStorageService } from './session-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { EntityAction } from '../enums/entity-action.enum';
import { TokenStore } from '../models/user/token-store.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  public userSession: UserSession = new UserSession();

  constructor(private session: SessionStorageService) {
    this.isAuthenticatedBySession();
  }

  userModelSetNotification$ = new BehaviorSubject<UserSession>(
    this.userSession
  );

  /**
   * Set the authHeader
   * Set the userSession tokens
   * Save the userSession state
   * @param tokens
   */
  set authorization(tokens: any) {
    this.userSession.tokens = tokens;
    this.saveUserSession();
  }

  /**
   * Set the userModel base
   * Save the userSession
   * @param data result from operation to return user model from API
   */
  setUser(userModel: UserModel) {
    let allowedActionsModel: AllowedActionsModel = new AllowedActionsModel();
    allowedActionsModel.setAllowedActions(
      this.convertActionsStringToEntityEnumFlags(
        userModel.resources.find(
          (d) =>
            d.resourceId === AppConstants.aquiraPlannerAuResources.demographics
        )?.actions
      ),
      this.convertActionsStringToEntityEnumFlags(
        userModel.resources.find(
          (d) => d.resourceId === AppConstants.aquiraPlannerAuResources.surveys
        )?.actions
      )
    );

    userModel.allowedActions = allowedActionsModel;
    this.userSession.userModel = userModel;
    this.saveUserSession();
  }

  logOut() {
    const accessToken = this.userSession.tokens.AccessToken;
    const returnUrl = window.location.origin;
    this.session.remove(AppConstants.userSessionName);
    this.userSession = undefined;

    window.location.href = `${environment.securityApiUrl}/authentication/logout?accessToken=${accessToken}&returnUrl=${returnUrl}`;
  }

  logIn(url: URL) {
    const u = new UserSession();
    u.updateSession(new TokenStore(url));
    this.userSession = u;
    this.saveUserSession();
  }

  /**
   * Called when first setting up a new user
   * stores new userSession for later retrival
   * @param user new userSession filled out
   *
   * */
  private saveUserSession() {
    this.session.add(AppConstants.userSessionName, this.userSession);
  }

  /**
   * Calling this will load up the session cookie values
   * if user is authenticated
   */
  isAuthenticated(): boolean {
    return this.isAuthenticatedBySession();
  }

  isAuthenticatedBySession() {
    const userSession = this.getUserSession();
    if (userSession) {
      this.userSession = userSession;
      this.userModelSetNotification$.next(userSession);
      return true;
    }
    return false;
  }

  getUserSession(): UserSession {
    const userSession: UserSession = this.session.get(
      AppConstants.userSessionName
    );
    return userSession;
  }

  getSessionObserver(): Observable<UserSession> {
    return this.userModelSetNotification$.asObservable();
  }

  hasAccess(area: string, action: EntityAction): boolean {
    let hasAccess =
      (this.userSession.userModel.allowedActions[area] & action) === action;
    return hasAccess;
  }

  isAdmin(): boolean {
    return this.userSession.userModel.isAdmin;
  }

  convertActionsStringToEntityEnumFlags(value: string): number {
    if (!value) {
      return 0;
    }

    const flags = value.split(',').map((flag) => flag.trim());
    let result = 0;

    for (const flag of flags) {
      const flagValue = EntityAction[flag];

      if (flagValue !== undefined) {
        result |= flagValue;
      }
    }

    return result;
  }
}
