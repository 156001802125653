import { LookupModel } from '../lookup-model';

export class DropDownModel extends LookupModel {
  isChecked?: boolean;

   constructor(id?: number | string, name?: string, isChecked?: boolean) {
      super(id, name);
      this.isChecked = isChecked;
   }
}
