<dx-drop-down-box
                [(value)]="selectedTexts"
                [class]="borderStyle"
                valueExpr="id"
                displayExpr="name"
                [deferRendering]="true"
                [dropDownOptions]="{width: width}"
                [contentTemplate] ="'content'"
                placeholder="{{'Select a value...'|translate}}"
                [showClearButton]="true"
                [opened]="opened"
                [openOnFieldClick]="true"
                (valueChange)="onDropDownValueChange($event)"
                (onInitialized)="onDropDownInitialised(condition)"
                [width]="width"
            >

            <dx-validator [adapter]="validationAdapter" >
              <dxi-validation-rule *ngIf="enableRequiredValidation"
                                   [type]="'required'"
                                   [message]="requiredValidationMessage" >
              </dxi-validation-rule>
            </dx-validator>

                <div  *dxTemplate="let data of 'content'">
                  <dx-scroll-view class="treelist-scroll-view" >
                    <dx-tree-list [dataSource]="dataSource"
                    [dataStructure]="dataStructure"
                    [wordWrapEnabled]="true"
                    [hoverStateEnabled]="true"
                    [columnAutoWidth]="true"
                    [focusedRowEnabled]="focusedRowEnabled"
                    [autoExpandAll]="true"
                    [class]="'multi-select-treeview-drop-down-overrides'"
                    [keyExpr]="'id'"
                    noDataText="{{'No data'|translate}}"
                    [showColumnLines]="false"
                    [showRowLines]="false"
                    [showBorders]="false"
                    [wordWrapEnabled]="true"
                    [parentIdExpr]="'parentId'"
                    (onFocusedRowChanged)="onSelectionChange($event)"
                    (onContentReady)="syncTreeViewSelection($event)"
                    (onSelectionChanged)="treeView_SelectionChanged($event)"
                    rootValue="-1"
                    >
                    <dxo-paging  [class]="'d-flex justify-content-left'" [enabled]="showPaging" [pageSize]="pageSize" [pageIndex]="startPageIndex"  >
                    </dxo-paging>
                    <dxo-pager  [class]="'d-flex justify-content-right'" [visible]="showPaging" [showNavigationButtons]="true" [showPageSizeSelector]="true" [allowedPageSizes]="allowedPages" infoText="{{'Page {0} of {1} ({2} items)'| translate}}"></dxo-pager>
                    <dxo-search-panel [highlightSearchText]="true" [searchVisibleColumnsOnly]="true"  [visible]="true"> </dxo-search-panel>
                    <dxo-selection mode="multiple" [allowSelectAll]="true" [recursive]="true" ></dxo-selection>
                    <dxo-scrolling [showScrollbar]="true" [useNative]="false" [mode]="'standard'">
                    </dxo-scrolling>
                    <dxi-column dataField="name"  [caption]="headerText" ></dxi-column>
                    </dx-tree-list>
                  </dx-scroll-view>
                </div>
</dx-drop-down-box>
<span *ngIf="showValidationMessage && isDropwDownFocused" class="validator-label">{{requiredValidationMessage}}</span>
