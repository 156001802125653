import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ScreenService, AppInfoService } from './shared/services';
import { AuthorizationService } from './core/services/authorization.service';
import { AppConstantService } from './core/services/app-constant.service';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { LoaderNotificationModel } from './core/models/ui/loader-notification-model';
import themes from 'devextreme/ui/themes';
import { TranslateService } from '@ngx-translate/core';
import enMessages from 'src/assets/i18n/dx.messages.en.js';
import zhMessages from 'src/assets/i18n/dx.messages.zh.js';
import { locale, loadMessages } from 'devextreme/localization';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(' ');
  }

  disposeSubscription$ = new Subject<boolean>();
  loaderNotification = new LoaderNotificationModel('Loading', false);
  isAuthenticated: boolean;

  constructor(
    public router: Router,
    private authService: AuthorizationService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    private globalService: AppConstantService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.setLanguage();

    this.checkTheme();
    this.authService.getSessionObserver().subscribe((userSession) => {
      this.isAuthenticated = Boolean(userSession.tokens?.AccessToken);
    });
    this.globalService.loaderNotification$
      .pipe(takeUntil(this.disposeSubscription$))
      .subscribe(
        (s) => (this.loaderNotification = s),
        (err) =>
          (this.loaderNotification = new LoaderNotificationModel('', false))
      );
  }

  ngOnDestroy(): void {
    this.disposeSubscription$.next(true);
    /*ensure sesssion is destroyed if browser is just closed*/
    this.authService.logOut();
  }

  isAuthorized(): boolean {
    return this.isAuthenticated;
  }

  checkTheme(): void {
    /**
     * apply the theme if saved or set a default
     */
    let savedTheme = this.globalService.defaultTheme;
    if (
      window.localStorage.getItem(this.globalService.localStorageThemeKeyName)
    ) {
      savedTheme = JSON.parse(
        window.localStorage.getItem(this.globalService.localStorageThemeKeyName)
      );
    } else {
      /**save the default theme */
      window.localStorage.setItem(
        this.globalService.localStorageThemeKeyName,
        JSON.stringify(savedTheme)
      );
    }

    themes.current(savedTheme.id);
  }

  private setLanguage() {
    //ngx-translate
    let browserCultureLang = this.translate.getBrowserCultureLang();
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.addLangs([browserCultureLang, 'en-AU', 'en']);
    this.translate.setDefaultLang('en-AU');

    // The lang to use. if the lang isn't available, it will use the current loader to get them
    this.translate.use(browserCultureLang);

    //DevExtreme build-in library. DevExtreme doesn't provide customisation for 'Add Group' and 'Add Condition' for filter builder on Demographic page.
    //Therefore, build-in library had to be included also.
    loadMessages(enMessages);
    loadMessages(zhMessages);
    locale(navigator.language);
  }
}
