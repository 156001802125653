import { DemographicFilterModel } from 'src/app/core/models/demographic/demographic-filter-model';

 /// <summary>
 /// A Demographic Filter Variable model
 /// </summary>
export class DemographicFilterVariableModel extends DemographicFilterModel {

  $type = 'AquiraPlanner_APIModels.Models.Demographic.DemographicFilterVariableModel, AquiraPlanner_APIModels'

 /// <summary>
 /// The Variable
 /// </summary>
 public  variableId: number;

  /// <summary>
  /// The variable values
  /// </summary>
  public  valueIds: number[];

}