import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DxToastModule } from 'devextreme-angular/ui/toast';
import { NotificationComponent } from './components/ui/notification/notification.component';
import { NotifyTemplateComponent } from './components/ui/notification/notify-template/notify-template.component';
import { CanAddPermissionPipe } from './pipe/can-add-permission.pipe';

@NgModule({
  declarations: [
    NotificationComponent,
    NotifyTemplateComponent,
    CanAddPermissionPipe
  ],
  imports: [
    CommonModule,
    DxToastModule
  ],
  exports: [
    NotificationComponent,
    NotifyTemplateComponent,
    CanAddPermissionPipe
  ],
  providers:[HttpClient]
})
export class CoreModule { }
