<ng-container *ngIf="isAuthorized(); else notAuthorized">
  <app-side-nav-outer-toolbar title="{{appInfo.title}}">
    <app-notification></app-notification>
    <dx-load-panel *ngIf="loaderNotification.showLoading" shadingColor="rgba(0,0,0,0.4)"
                   [showIndicator]="true"
                   [showPane]="true"
                   [shading]="true"
                   [animation]="true"
                   [visible]="true"
                   [message]="loaderNotification.loadingMessage"
                   [closeOnOutsideClick]="true"
                   >
    </dx-load-panel>
    <router-outlet></router-outlet>
  </app-side-nav-outer-toolbar>
</ng-container>

<ng-template #notAuthorized>
  <app-double-card>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </app-double-card>
</ng-template>
