export class AllowedActionsModel {
  allowedActions: number = 0;
  companies: number = 0;
  demographics: number;
  surveys: number;
  users: number = 0;

  /**assign area actions flag enum here */
  public setAllowedActions(
    demographicsAllowedActions: number,
    surveysAllowedActions: number
  ) {
    this.demographics = demographicsAllowedActions;
    this.surveys = surveysAllowedActions;
  }
}
