import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserModel } from 'src/app/core/models/user/user-model';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { SecurityApiService } from 'src/app/core/services/security/security.service';
import { UserService } from 'src/app/core/services/user/users.service';
import { AppConstants } from 'src/app/app-constants';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss'],
})
export class LoginCallbackComponent implements OnInit {
  constructor(
    private auth: AuthorizationService,
    private router: Router,
    public userService: UserService,
    private securityApiService: SecurityApiService
  ) {}

  async ngOnInit() {
    const url: URL = new URL(window.location.href);

    if (url.searchParams.get('AccessToken')) {
      this.auth.logIn(url);
      this.getInfoFromUserToken().subscribe((s) => {
        this.router.navigate([`/${AppConstants.routePaths.home}`]),
          { queryParams: {} };
      });
    }
  }

  getInfoFromUserToken() {
    const session = this.auth.getUserSession();
    const authToken = JSON.parse(
      atob(session.tokens.AccessToken.split('.')?.[1])
    );
    return this.securityApiService.getTokenById(authToken.jti).pipe(
      map((results) => {
        const content = results.data.content;
        this.auth.setUser(
          new UserModel(content.currentUser, content.accessTokenExpiry)
        );
      })
    );
  }
}
