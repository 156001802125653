import { Component, OnInit } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { EntityAction } from 'src/app/core/enums/entity-action.enum';
import { EntityArea } from 'src/app/core/enums/entity-area.enum';
import { NotifyType } from 'src/app/core/enums/notify-type.enum';
import { HttpResult } from 'src/app/core/helpers/http-result';
import { CheckActions } from 'src/app/core/interface/check-actions';
import { CompanyModel } from 'src/app/core/models/company/company-model';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { CompaniesService } from 'src/app/core/services/companies/company.service';
import { NotifyService } from 'src/app/core/services/notify.service';
import { UserService } from 'src/app/core/services/user/users.service';
import { TranslationService } from 'src/app/shared/services';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit, CheckActions {
  dataSource: any;
  editingTexts: any;
  focusedRowKey: string;
  newRowKey: string;
  operationDescriptions: any;
  entityArea : EntityArea = EntityArea.Company;

  constructor(private companyService: CompaniesService,
              private globalService: AppConstantService,
              private notifyService: NotifyService,
              private translation: TranslationService,
              private userService: UserService,
              private authService: AuthorizationService
              ) {
    this.delete = this.delete.bind(this);
    this.checkDeleteAccess = this.checkDeleteAccess.bind(this);
    this.checkUpdateAccess = this.checkUpdateAccess.bind(this);
  }
  
  checkUpdateAccess(e): boolean {
    return this.authService.hasAccess(EntityArea.Company,EntityAction.Update);
  }
  
  checkDeleteAccess(e): boolean {
    return this.authService.hasAccess(EntityArea.Company,EntityAction.Delete);
  }

  delete(e){
    let inUse = false;
    this.globalService.showLoadingNotification(true, this.translation.translateSingle(marker('Checking if this company is in use on users...')));
    
    this.userService.getUsers().subscribe(
      (result) => {
        for(let i = 0; i < result.data.length; i++){
          if(result.data[i].companyUuid === e.row.key){
            inUse = true;
            this.notifyService.notifyWarning(this.translation.translateSingle(marker("You cannot delete a company that is in use on users.")), this.translation.translateSingle(marker('Validation')));
            return;
          }
        }
      },
      (err)=>{},
      ()=>{
        this.globalService.showLoadingNotification(false);
        if(!inUse){
          e.component.deleteRow(e.row.rowIndex);
        }
      }
    );
  }

  doesNameExist(name: string): boolean{
    for(let i=0;i<this.dataSource.length;i++){
      if(this.dataSource[i].name.toLowerCase() === name.toLowerCase()){
        this.notifyService.notifyWarning(this.translation.translateSingle(marker(`Company with this name already exists.`)), this.translation.translateSingle(marker("Validation")));
        return true;
      }
    }
  }

  async ngOnInit() {
    this.editingTexts = await this.translation.getEditingTexts();
    this.operationDescriptions = await this.translation.getOperationDescriptions();
    this.companyService.getCompaniesLookup().subscribe((res:any)=>{
      this.dataSource = res.data;
    });
  }

  onContentReady(e){
    e.component.columnOption("command:edit", "visibleIndex", -1);
  }

  onRowInserting(e:any): void{
    e.cancel = this.doesNameExist(e.data.name);
  }

  onRowInserted(e): void {
    this.companyService.addCompany(e.data)
        .subscribe(
          (resp) => {
            this.notifyService.notifySuccess(this.translation.translateSingle(marker('Company Added')), this.translation.translateSingle(marker('Success')));
            this.focusedRowKey = e.data.companyUuid;
            this.newRowKey = e.data.companyUuid;
          },
          (err: HttpResult<CompanyModel>) => {
            this.notifyService.showNotification(err.friendlyMessage , NotifyType.Error, err.statusText);
          }
      );
  }

  onRowRemoved(e:any): void{
    this.companyService.deleteCompany(e.data.companyUuid).subscribe(
      (resp) => {
        this.notifyService.notifySuccess(e.data.name + " " + this.translation.translateSingle(marker("deleted")), this.translation.translateSingle(marker("Success")));
      },
      (err: HttpResult<CompanyModel>) => {
        this.notifyService.showNotification(err.friendlyMessage, NotifyType.Error, err.statusText);
      }
    );
  }

  onRowUpdated(e): void {
    this.companyService.updateCompany(e.data)
        .subscribe(
          (resp) => {
            this.notifyService.notifySuccess(this.translation.translateSingle(marker("Company saved")), this.translation.translateSingle(marker('Success')));
          },
          (err: HttpResult<CompanyModel>) => {
            this.notifyService.notifyError(err);
          }
        );
  }

  onRowUpdating(e:any): void{
    e.cancel = this.doesNameExist(e.newData.name);
  }
}