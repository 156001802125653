import { Component, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxScrollViewModule }  from 'devextreme-angular/ui/scroll-view';

@Component({
  selector: 'app-double-card',
  templateUrl: './double-card.component.html',
  styleUrls: ['./double-card.component.scss']
})
export class DoubleCardComponent {
  constructor() { }
}

@NgModule({
  imports: [ CommonModule, DxScrollViewModule ],
  exports: [ DoubleCardComponent ],
  declarations: [ DoubleCardComponent ]
})
export class DoubleCardModule { }
