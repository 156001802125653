import { HttpHeaders } from '@angular/common/http';

export class HttpResult<T> {

 public isSuccess: boolean;
 public data: T;
 public errorMessage: string;
 public statusCode: number;
 public friendlyMessage: string;
  /**
  * All response headers.
  */
 public headers: HttpHeaders;
 /**
  * Response status code.
  */
  public status: number;
 /**
  * Textual description of response status code.
  *
  * Do not depend on this.
  */
  public statusText: string;
 /**
  * URL of the resource retrieved, or null if not available.
  */
  public url: string | null;
 /**
  * Whether the status code falls in the 2xx range.
  */
  public ok: boolean;
}