import { DemographicFilterGroupModel } from 'src/app/core/models/demographic/demographic-filter-group-model';
import { DemographicFilterVariableModel } from 'src/app/core/models/demographic/demographic-filter-variable-model';
import { VariableModel } from 'src/app/core/models/variable-model';
import { DemographicFilterModel } from 'src/app/core/models/demographic/demographic-filter-model';
import { DemographicFilterJoinType } from 'src/app/core/enums/demographic-filter-join-type.enum';

export class DxFilterModelBuilder {

  groupTypeName = 'DemographicFilterGroupModel';
  filterItemTypeName = 'DemographicFilterVariableModel';

  constructor(private variables: VariableModel[]) {

  }

  getFilterBuilderVariables(apiModel: DemographicFilterModel) {

    let variableLookup = new Array<VariableModel>();

     const getFilterVariables = (group: DemographicFilterModel) => {
      if (group && group.filters && group.filters.length > 0) {
        let filterVariables = [];
        //Check if three-level works
        filterVariables = [...filterVariables, ...this.getVariableModels(group.filters.filter(f=>this.isFilterItem(f)))];
        group.filters.forEach( v => {
          if (!this.isFilterItem(v)) {
            //filterVariables = [...filterVariables, ...this.getVariableModels(group.filters.filter(f=>this.isFilterItem(f)))];
          //} else {
            filterVariables = [...filterVariables, ...this.getVariableModels(v.filters)];
          }
        });

        variableLookup = [...variableLookup, ...filterVariables];
      }

      this.getFilterGroups(group.filters).forEach(v => {
          this.getFilterVariables(v);
        });
    };

    getFilterVariables(apiModel);
    variableLookup = variableLookup.filter((elem, index, arr) => arr.findIndex(f => f.id === elem.id) === index);
    return variableLookup;

  }

  getDxFilterModel(apiModel: DemographicFilterModel): any[] {
    return this.recursiveModelBuilder(apiModel);
  }

  private recursiveModelBuilder(group: DemographicFilterModel): any[] {

    let dxModel = [];
    // get operation
    const operation = this.getOperation(group);

    // get filter items arrays
    if (group && group.filters && group.filters.length > 0) {
      const filterVariables = this.getFilterVariables(group.filters);
      if (filterVariables) {
        filterVariables.forEach(v => {
          dxModel.push(v);
          dxModel.push(operation);
        });
      }
      // get filter groups
      const filterGroups = this.getFilterGroups(group.filters);
      if (filterGroups) {
        filterGroups.forEach(v => {
          const grp = this.recursiveModelBuilder(v);
          dxModel.push(grp);
        });
      }
    }

    //What's the purpose of presentationLogics folder, which contains this dx-filter-model-builder, when there are 2 files with the same name inside and outside of this folder in UK
    //The following code doesn't exist outside of presentationLogics
    // if (dxModel.length <= 0) {
    //   dxModel = ['and'];
    // }

    return dxModel;
  }

  private getOperation(group: DemographicFilterModel) {
    const operator = group.joinType;
    if (operator === DemographicFilterJoinType.And) {
      return 'and';
    } else if (operator === DemographicFilterJoinType.Or) {
      return 'or';
    }
  }

  private getFilterVariables(group: DemographicFilterModel[]): any[] {
      const filterValues = new Array<any>();
      if (group && group.length > 0) {
      group.forEach( v => {
        if (this.isFilterItem(v)) {
          const filterVariable = v as DemographicFilterVariableModel;
          const item = this.setVariableValues(filterVariable);
          filterValues.push(item);
        }
      });
    }
      return filterValues;

  }

  private getVariableModels(group: DemographicFilterModel[]): VariableModel[] {
    const filterValues = new Array<VariableModel>();
    if (group && group.length > 0) {
      group.forEach( v => {
        if (this.isFilterItem(v)) {
          const filterVariable = v as DemographicFilterVariableModel;
          const variableModel = new VariableModel();
          variableModel.id = filterVariable.variableId;
          variableModel.name = this.variables.find(f => f.variableId === filterVariable.variableId).description;
          filterValues.push(variableModel);
        }
      });
  }
    return filterValues;
  }

  //Group is the join type
  private isGroup(group) {
    if (group.joinType > 0 && group.filters !== null) {
      return true;
    }
    return false;
  }

  private isFilterItem(item) {
    if (item.variableId) {
      return true;
    }
    return false;
  }

  private getFilterGroups(group: DemographicFilterModel[]): any[] {
      const filterGroups = new Array<any>();
      group.forEach( v => {
        if (this.isGroup(v)) {
          const groups = v as DemographicFilterGroupModel;
          filterGroups.push(groups);
        }
      });
      return filterGroups;
  }

  private setVariableValues(filter: DemographicFilterVariableModel) {
    if (filter.variableId && filter.valueIds) {
      const variable = this.variables.find(variable => variable.variableId === filter.variableId);
      const variableDescription = variable.description;

      const valueArray = [];
      filter.valueIds.forEach( v => {
        valueArray.push(variable.values.find(f => f.valueId === v));
       });
      const valueNames = valueArray.map( m => m.description);
      return [variableDescription, 'contains', valueNames];
    }
  }
}


export class DxFilterGroup {
  joinType: string;
  fitlers: DxFilterItem[];
  groups: DxFilterGroup[];
}

export class DxFilterItem {
  variableName: string;
  operation: string;
  values: string[];
}