import { Component, OnInit, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DemographicsService } from 'src/app/core/services/demographics/demographics.service';
import { DemographicLookupModel } from 'src/app/core/models/demographic/demographic-lookup-model';
import { MultiSelectLookupModel } from 'src/app/core/models/multi-select-lookup-model';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { LookupModel } from 'src/app/core/models/lookup-model';
import { ApiResponse } from 'src/app/core/models/api-response.model';

@Component({
  selector: 'app-demographics-single-select-look-up',
  templateUrl: './demographics-single-select-look-up.component.html',
  styleUrls: ['./demographics-single-select-look-up.component.scss']
})
export class DemographicsSingleSelectLookUpComponent implements OnInit, OnChanges, OnDestroy {

  @Input() initialVal = 0;
  @Input() demographicsSelection: LookupModel;
  @Output() demographicsSelectionChange = new EventEmitter();
  
  demographicLookUp = new Array<MultiSelectLookupModel>();
  unSubscribe$ = new Subject<boolean>();

  constructor(private demographicService: DemographicsService,
              private appService: AppConstantService) { }

  ngOnInit() {
    this.loadDemographicsLookUp();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  loadDemographicsLookUp() {
    this.demographicService.get<ApiResponse<DemographicLookupModel[]>>(this.appService.apiPath.demographicLookups)
    .pipe(
      takeUntil(this.unSubscribe$)
    )
    .subscribe(
        s => {
          this.onDemographicGetSuccess(s.data.entity)
        }
    )
  }

  onDemographicGetSuccess(demographic: DemographicLookupModel[]) {
    this.demographicLookUp = demographic.map(a => ({ id : a.demographicUuid, name: a.description, isChecked: false }));
  }

  onSelectionChanged(selectedItem: LookupModel) {    
    this.demographicsSelectionChange.emit(selectedItem);
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
  }
}
