<div class="content-block">
  <img
    src="{{ logo }}"
    class="logo"
    alt="{{ 'Aquira Planner AU' | translate }}"
  />
  <div class="login">
    <form (submit)="signIn($event)">
      <dx-form [formData]="loginModel" labelLocation="top">
        <dxi-item
          dataField="username"
          editorType="dxTextBox"
          [editorOptions]="emailInputOptions"
        >
          <dxi-validation-rule
            type="required"
            message="{{ 'Email is required' | translate }}"
          >
          </dxi-validation-rule>
          <dxi-validation-rule
            type="email"
            message="{{ 'Email is invalid' | translate }}"
          ></dxi-validation-rule>
          <dxo-label
            [visible]="true"
            text="{{ 'Email' | translate }}"
          ></dxo-label>
        </dxi-item>
        <dxi-item>
          <input
            type="hidden"
            id="returnUrl"
            name="returnUrl"
            value="{{ loginModel.returnUrl }}"
          />
        </dxi-item>
        <dxi-item
          itemType="button"
          [buttonOptions]="{
            text: 'Login' | translate,
            useSubmitBehavior: true
          }"
        >
        </dxi-item>
      </dx-form>
    </form>
  </div>
</div>
