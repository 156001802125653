<div *dxTemplate="let notifyType of templateType">
    <div  [class]="'card ' + tempClass" >
      <div class="toaster-header p-2 pl-2 toast-title">        
        <span class="font-weight-bold"><i [class]="templateIcon + ' mr-2'"></i>{{notifyModel.title}}</span>      
        <label (click)="toaster_onClose($event)" class="float-right">
          <i class="fas fa-times close cursor-pointer"></i>         
        </label>
      </div>
      <div>
        <div class="d-flex flex-column pt-1">
        <ng-container *ngFor="let msg of notifyModel.messages">         
          <label class="ml-4 pl-1 pt-2 pb-2">
            {{msg}}
          </label>
        </ng-container>
        </div>
      </div>
    </div>
</div>
