
<div class="col-12">
    <!-- <app-form-label [text]="'Demographics Variables'" ></app-form-label>
    <app-multi-select-drop-down
    [disabled]="isViewOnly"
    [(dropDownValues)]="demographicVariablesLookup"
    [enableRequiredValidation]="true"
    [requiredValidationMessage]="'At least one demographics variable is required'"
    [enableSearch]="true"
    (dropDownOpened)="onDemographicLookupOpened($event)"
    (saveChanges)="onApplyChanges($event)">
    </app-multi-select-drop-down>
  <br /> <br /> -->
  <app-form-label [text]="'Filter builder'" ></app-form-label>
  <dx-filter-builder
  #dxFilter
  (onValueChanged)="dxFilterBuilder_onValueChange($event)"
  [allowHierarchicalFields]="false"
  [filterOperationDescriptions]="filterOperationDescriptions"
  [groupOperations]="['and', 'or']"
  [disabled]="disableFilterBuilder"
  [customOperations]="anyOfOperation"
  [activeStateEnabled]="disableFilterBuilder"
  [elementAttr]="{ 'overflow-x': 'scroll'}"
  [(value)]="filterModel"
  >
    <dxi-field
    #field
      *ngFor="let item of filterBuilderFields; trackBy: trackFilterVariables"
      [editorOptions]="{data: item}"
      [editorTemplate]="'betweenTemplate'"
      [filterOperations]="anyOfOperation"
      [dataField]="item.name"
      [allowSorting]="true"
      [allowFiltering]="true"
      [wordWrapEnabled]="false"
      [caption]="item.name"
      dataType="number">
      <div *dxTemplate="let condition of 'betweenTemplate'">
        <dx-drop-down-box
        [opened]="showFilterValuesDropDown"
        [value]="'Select filters......'"
        (onInitialized)="onInitialised(condition)"
        [contentTemplate]="'template'"
        [disabled]="disableFilterBuilder"
        [width]="900"
      >
        <div *dxTemplate="let it of 'template'" style="overflow-y: scroll;">
          <div class="d-block clearfix ml-4">
            <div class="d-inline" *ngIf="ageVariableType">
            <label>Specify range: </label>
            <span class="ml-2">  </span>
            <input class="num-box" [(ngModel)]="betweenMinVal" type="number" />
            <span>  -  </span>
            <input class="num-box" [(ngModel)]="betweenMaxVal" type="number" />
          </div>
            <dx-check-box class="ml-3" [(ngModel)]="checkSelectAllValues" text="{{'Select All'|translate}}" [value]="false" (valueChange)="demoCheckSelectAll_checked($event)"  [name]="'Select All'"></dx-check-box>
            <label class="ml-4 lbl-icon" (click)="demoCheckSelectAll_checked(false)"> <i class="fa fa-times icon-sm"></i> Unselect All</label>
            <dx-button class="ml-3" #btn [text]="'Apply Filters'"  (onClick)="saveFilterValuesChanges(condition)" [type]="'success'" [useSubmitBehavior]="true"></dx-button>
          </div>
          <hr />
          <div class="d-block  mt-4">
            <div class="clearfix">
            <div class="mt-3 ml-4 float-left" style="width:220px;" *ngFor="let value of filterDropDownValues; trackBy: trackDemographicValues">
              <dx-check-box [text]="value.name" [elementAttr]="{class: 'demographicFilterValue'}" [value]="value.isChecked" (valueChange)="demoChkValueSelect_checked($event, value)"  [name]="value.name"></dx-check-box>
            </div>
          </div>
          </div>
        </div>
      </dx-drop-down-box>
      </div>
    </dxi-field>
  </dx-filter-builder>
</div>
