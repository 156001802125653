import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlaceModel } from 'src/app/core/models/place-model';
import { GridColumn } from 'src/app/core/models/ui/grid-column';
import { NotifyService } from 'src/app/core/services/notify.service';
import { PlacesService } from 'src/app/core/services/place/places.service';
import { GridviewComponent } from '../../shared/components/gridview/gridview.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslationService } from 'src/app/shared/services';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss']
})
export class PlacesComponent implements OnInit, OnDestroy {

  placeModel: PlaceModel[] = [];
  disposeSubscription$ = new Subject();
  gridColumns: GridColumn[];

  @ViewChild(GridviewComponent)grid: GridviewComponent

  constructor(private placesService: PlacesService,
              private notifyService: NotifyService,
              private translation: TranslationService) {
                this.initializeGridColumns();
               }

  ngOnInit() {
    this.getPlaces();

  }

  initializeGridColumns() {
    this.gridColumns = [
      new GridColumn(this.translation.translateSingle(marker('Place ID')), 'placeId', true),
      new GridColumn(this.translation.translateSingle(marker('Place Description')), 'placeDesc', true),
    ]
  }

   getPlaces() {
     this.placesService.getPlaces()
         .pipe(
           takeUntil(this.disposeSubscription$)
         )
         .subscribe(
           s => {this.placeModel = s.data.entity},
           error => {this.notifyService.notifyError(error)}
         )
   }
   
   ngOnDestroy() {
     this.disposeSubscription$.next(true);
   }

}
