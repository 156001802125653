
<app-look-up
[cssClass]="'form-control'"
[dataSource]="demographicLookUp"
[enableSearch]="true"
[enableRequiredValidation]="true"
requiredValidationMessage = "{{'Demographics Required'|translate}}"
(selectionChange)="onSelectionChanged($event)"
>
</app-look-up>
