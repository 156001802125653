import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { NavigationAction } from 'src/app/core/enums/navigation-action.enum';
import { NotifyType } from 'src/app/core/enums/notify-type.enum';
import { UserRole } from 'src/app/core/enums/user-role.enum';
import { HttpResult } from 'src/app/core/helpers/http-result';
import { CompanyLookupModel } from 'src/app/core/models/company/company-lookup-model';
import { EnumModel } from 'src/app/core/models/enum/enum-model';
import { ToolbarItems } from 'src/app/core/models/tool-bar-items';
import { UserModel } from 'src/app/core/models/user/user-model';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { CompaniesService } from 'src/app/core/services/companies/company.service';
import { NotifyService } from 'src/app/core/services/notify.service';
import { ToolbarService } from 'src/app/core/services/toolbar.service';
import { UserService } from 'src/app/core/services/user/users.service';
import * as $ from 'jquery';
import { DxiItemComponent } from 'devextreme-angular/ui/nested';
import { TranslationService } from 'src/app/shared/services';
import { CheckActions } from 'src/app/core/interface/check-actions';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { EntityArea } from 'src/app/core/enums/entity-area.enum';
import { EntityAction } from 'src/app/core/enums/entity-action.enum';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit, CheckActions {
  companies: CompanyLookupModel[];
  disableEdit: boolean = false;
  focusedRowKey: string;
  isViewOnly = false;
  navActionType: NavigationAction;
  navigationActions = NavigationAction;
  operationDescriptions: any;
  router: Router;
  toolbarItems: ToolbarItems;
  toolbarService: ToolbarService;
  unSubscribeUserDetail$ = new Subject<void>();
  userRoles: EnumModel[];
  user: UserModel;
  userData = new Array<UserModel>();
  entityArea: EntityArea = EntityArea.User;

  constructor(private appService: AppConstantService,
              private companyService: CompaniesService,
              private notifyService: NotifyService,
              private translation: TranslationService,
              private userService: UserService,
              private authService: AuthorizationService
              ) {
    
      this.checkDeleteAccess = this.checkDeleteAccess.bind(this);
      this.checkUpdateAccess = this.checkUpdateAccess.bind(this);
  }

  checkUpdateAccess(e?: any): boolean {
    let canUpdate = this.authService.hasAccess(EntityArea.User,EntityAction.Update);
    return canUpdate;
  }

  checkDeleteAccess(e?: any): boolean {
    let canDelete = this.authService.hasAccess(EntityArea.User,EntityAction.Delete);
    return canDelete;
  }
  
  async ngOnInit() {
    if(this.authService.isAuthenticated()){
      this.user = this.authService.userSession.userModel;
      this.disableEdit = !(this.user?.role === UserRole.SystemAdministrator || this.user?.role === UserRole.CompanyAdministrator);
    }
    this.operationDescriptions = await this.translation.getOperationDescriptions();
    this.companies = undefined;
    this.loadUser();
    this.getCompanies();
    this.setUserRoleStrings();    
  }

  loadUser() {
    this.userService.getUsers().subscribe( s => {
       this.userData = s.data
    });
  }
  
  getCompanies(): void {
    this.companyService.getCompaniesLookup()
      .subscribe(
        (resp) => {
          this.companies = resp.data.entity.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        },
        (err: HttpResult<CompanyLookupModel>) => {
          this.notifyError(err);
        }
      )
  }

  notifyError<T>(message: HttpResult<T>) {
    this.notifyService.showNotification(message.friendlyMessage , NotifyType.Error, message.statusText);
    console.log(message);
  }

  onContentReady(e){
    e.component.columnOption("command:edit", "visibleIndex", -1);
    if(this.userData.length){
      e.component.endCustomLoading();
    } else {
      e.component.beginCustomLoading();
    }
  }

  onRowInserting(e:any): void{
    e.cancel = true;
    this.userService.post(this.appService.apiPath.users, e.data)
        .subscribe(
          (resp) => {
            this.focusedRowKey = resp.data["userId"];
            this.notifyService.showNotification('User Added', NotifyType.Success, 'Success');
          },
          (err: HttpResult<UserModel>) => {
            this.notifyService.showNotification(err.friendlyMessage , NotifyType.Error, err.statusText);
          }
      );
  }

  onRowRemoved(e:any): void{
    this.userService.deleteByID(this.appService.apiPath.users, e.data.userId).subscribe(
      (resp) => {
        this.notifyService.showNotification(e.data.userName + ' deleted', NotifyType.Success);

      },
      (err: HttpResult<UserModel>) => {
        this.notifyError(err);
      },
      () => {
        this.user = undefined;
      }
    );
  }

  onRowUpdated(e): void {
    this.userService.updateUser(e.data)
        .subscribe(
          (resp) => {
            this.notifyService.showNotification('User Saved', NotifyType.Success, 'Success');
          },
          (err: HttpResult<UserModel>) => {
            this.notifyError(err);
          }
        );
  }

  setUserRoleStrings(): void {
    this.userRoles = new Array<EnumModel>();

    for (const userRole in UserRole) {
      if (Number(userRole)>=0) {
        this.userRoles.push({ id: +userRole, name: UserRole[userRole] });
      }
    }
    /**if this is a non sysAdmin, remove sysAdmin role */
    if (!(this.user?.role === UserRole.SystemAdministrator)){

      let index = this.userRoles.findIndex(x => x.id === UserRole.SystemAdministrator)
      if(index > -1){
        this.userRoles.splice(index,1)
      }
    }
  }
}