import { TokenStore } from './token-store.model';
import { UserModel } from './user-model';

export class UserSession {
  /**
   * tokens contains
   * .accessToken and .refreshToken properties
   */
  public tokens: TokenStore;
  public userModel: UserModel;

  updateSession(tokenStore: TokenStore) {
    this.tokens = tokenStore;
  }
}
