import { DxiDataGridColumn } from "devextreme-angular/ui/nested/base/data-grid-column-dxi";

 /**
  * this class maps to the  DxiDataGridColumn class. So if you need to add more 
  * look at the DxiDataGridColumn and add the properties to this class
 **/
export class GridColumn {

  width?: number | string;

  alignment?: string;

  type?: string; 


  constructor(public caption: string,
              public dataField: string,
              public visible?: boolean,
              public format?: any,
              width?: string,
              alignment?: string) {
                this.caption = caption;  
                this.alignment = alignment ?? 'left';  
                this.width = width;
                            
              }

}
