<app-container title="{{'Variables' | translate}}">
  <app-content>
    <dx-data-grid
    [dataSource]="dataSource"
    [showBorders]="true"
    [focusedRowEnabled]="true"
    [focusedRowIndex]="0"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="false"
    keyExpr="variableId"
    [masterDetail]="{ enabled: true, template: 'detail' }"
    [rowAlternationEnabled]="true"
    [showColumnLines]="true">
  
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager infoText="{{'Page {0} of {1} ({2} items)'| translate}}" [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
    <dxo-filter-row [operationDescriptions]="operationDescriptions" resetOperationText="{{'Reset' | translate}}" [visible]="true"></dxo-filter-row>
    <dxo-sorting ascendingText="{{'Sort Ascending' | translate}}" clearText="{{'Clear Sorting'|translate}}" descendingText="{{'Sort Descending'|translate}}"></dxo-sorting>
  
    <dxi-column
      alignment="left"
      caption="{{'Variable ID' | translate}}"
      dataField="variableId"
      width="110px">
    </dxi-column>
    <dxi-column
      caption="{{'Description' | translate}}"
      dataField="description"
      sortOrder="asc">
    </dxi-column>
    <dxi-column
      caption="{{'Start Date' | translate}}"
      dataField="startDate"
      dataType="date"
      format="dd/MM/yyyy">
    </dxi-column>
    <dxi-column
      caption="{{'End Date' | translate}}"
      dataField="endDate"
      dataType="date"
      format="dd/MM/yyyy">
    </dxi-column>
  
    <div *dxTemplate="let variable of 'detail'">
      <dx-data-grid 
          [dataSource]="variable.data.values"
          [loadPanel]="{enabled:true, text: 'Loading...'|translate}"
          [showBorders]="true"
          [showColumnLines]="true">
              <dxi-column dataField="valueId" alignment="left" caption="{{'Value ID' | translate}}" width="100px"></dxi-column>
              <dxi-column dataField="variableId" alignment="left" caption="{{'Variable ID' | translate}}" width="100px"></dxi-column>
              <dxi-column dataField="description" caption="{{'Description' | translate}}"></dxi-column>
      </dx-data-grid>       
    </div>
  
  </dx-data-grid>
  </app-content>
</app-container>