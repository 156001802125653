import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-label',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss']
})
export class FormLabelComponent implements OnInit {
  @Input()
  text = '';
  @Input()
  for = '';
  constructor() { }

  ngOnInit() {
  }

}
