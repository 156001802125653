import { environment } from './../environments/environment';

export class AppConstants {

  static companyName = 'RCS';
  static copyrightMessage = 'Copyright © 2018';
  static applicationVersion = '0.00.0.0';
  static userSessionName = 'user';
  static notifyDisplayTime = 1500;
  static gmt = 'GMT';
  static dateTimeFormatString = 'YYYY-MM-DD HH:mm:ss';
  static specialCharacters = ['|',  '/', ':', '*', '?', '<', '>', '\\'];

  static routePaths = {
    logout: 'pages/logout',
    loginCallback: 'login/callback',
    home: 'home',
    login: 'login',
    users : 'pages/users',   
    places: 'pages/places',
    markets: 'pages/markets',
    companies: 'pages/companies',
    demographics: 'pages/demographics',
    profile: 'profile',
    surveys: 'pages/surveys',  
    calculations: 'pages/calculations',
    variables: 'pages/variables',   
    unknown: '**'
  };


  static dateTimeFormatSetting = {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  };


  // formatting options for the decimals number for two decimal places
  static decimalFormatOption = {
    type: 'fixedPoint', precision: 2
  };

  static variableFilterOperator = [
    {id: 1009, operator: ['between']},
    {id: 1010, operator: ['between']},
    {id: 1011, operator: ['between']},
    {id: 1012, operator: ['between']},
    {id: 1013, operator: ['between']},
    {id: 2003, operator: ['between']}
  ];

  // the below propery holds all the css class which need to be accessed in the code
  static css = {
    notify : {
      success: 'dx-toast-success',
      error: 'dx-toast-error',
      info: 'dx-toast-info',
      warning: 'dx-toast-warning'
    }
  };

  dxFormEditorType = {
    Autocomplete : 'dxAutocomplete',
    TextArea : 'dxTextArea',
    TextBox : 'dxTextBox',
    DropDownBox : 'dxDropDownBox',
    Lookup : 'dxLookup',
    NumberBox : 'dxNumberBox',
    CheckBox : 'dxCheckBox',
    TagBox : 'dxTagBox',
    RangeSlider : 'dxRangeSlider',
    HtmlEditor : 'dxHtmlEditor',
    RadioGroup : 'dxRadioGroup',
    ColorBox : 'dxColorBox',
    Calendar : 'dxCalendar',
    DateBox : 'dxDateBox',
    SelectBox : 'dxSelectBox',
    Slider : 'dxSlider',
    Switch : 'dxSwitch'
  }

  static notifyOption = {
      position: {
        my: 'top right',
        at: 'top right',
        of: window,
        offset: '0 0'
      }
     // elementAttr: elemAttr,
    //  message: 'success'
    };

  static aquiraPlannerAuResources = {
    demographics: 'AquiraPlannerAU.Demographics',
    surveys: 'AquiraPlannerAU.Surveys'
  }
}
