export class TokenStore {
  public AccessToken: string;
  public RefreshToken: string;

  /**
   * construct Access and Refresh Tokens from passed URL object
   */
  constructor(url?: URL) {
    this.AccessToken = url?.searchParams.get('AccessToken');
    this.RefreshToken = url?.searchParams.get('RefreshToken');
  }
}
