
import { Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { HttpResult } from '../helpers/http-result';
import { AppInjectorService } from './app-injector.service';
import { AppConstantService } from './app-constant.service';
import { RequestOptions } from '../helpers/requestParams';
import { HttpResponseType } from '../enums/http-response-type.enum';
import { ObserveResponse } from '../enums/observe-response.enum';
import { throwError } from 'rxjs/internal/observable/throwError';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Router } from '@angular/router';
import { AppConstants } from './../../app-constants';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root'
})

export class HttpService {

    httpClient: HttpClient;
    appConstantService: AppConstantService;
    domain = '';
    router: Router;
    translateService : TranslateService;
    notifyService : NotifyService

    constructor()
    {
      const injector = AppInjectorService.getInjector();
      this.httpClient = injector.get(HttpClient);
      this.router = injector.get(Router);
      this.appConstantService = injector.get(AppConstantService);
      this.notifyService = injector.get(NotifyService);
      this.domain = this.appConstantService.apiURL + this.appConstantService.urlSeparator;
      this.translateService = injector.get(TranslateService);
    }
  
 
    public get<T>(path: string, reqParams?: RequestOptions): Observable<HttpResult<T>> {

      const options = this.getRequestOptions(reqParams);

      return this.httpClient.get<T>(this.domain + path, options)
                            .pipe(
                                map(resp => this.handleSuccess<T>(resp)),
                                catchError(error => this.handleError<T>(error))
                                );
    }


    public getByID<T>(path: string, id: number): Observable<HttpResult<T>> {

      path += `/${id}`;
      return this.get<T>(path);
    }

    public getByStringID<T>(path: string, id: string): Observable<HttpResult<T>> {
      path += `/${id}`;
      return this.get<T>(path);
    }


    public post<T>(path: string, model: any, reqParams?: RequestOptions)
    : Observable<HttpResult<T>> {

      const options = this.getRequestOptions(reqParams);

      return this.httpClient.post<T>(this.domain + path, model, options)
        .pipe(
        map(resp => this.handleSuccess<T>(resp)),
        catchError(error => this.handleError<T>(error)),
        );
    }

    public postByID<T>(path: string, id: number, model: any): Observable<HttpResult<T>> {

      path += `/${id}`;
      return this.post(path, model);
    }

    public put<T>(path: string, model: any, reqParams?: RequestOptions)
    : Observable<HttpResult<T>> {

      const options = this.getRequestOptions(reqParams);
              return this.httpClient.put<T>(this.domain + path, model, options)
                          .pipe(
                          map(resp => this.handleSuccess<T>(resp)),
                          catchError(error => this.handleError<T>(error)),
                        );
    }


    public putByID<T>(path: string, id: number, model: any): Observable<HttpResult<T>> {

      path += `/${id}`;
        return this.put(path, model);
    }


    public delete<T>(path: string, reqParams?: RequestOptions)
    : Observable<HttpResult<T>> {

      const options = this.getRequestOptions(reqParams);

              return this.httpClient.delete<T>(this.domain + path, options)
                          .pipe(
                          map(resp => this.handleSuccess<T>(resp)),
                          catchError(error => this.handleError<T>(error)),
                        );
    }

    public deleteByID<T>(path: string, id: number): Observable<HttpResult<T>> {

      path += `/${id}`;
        return this.delete(path);
    }

    private handleError<T>(error: HttpErrorResponse): Observable<HttpResult<T>> {
      const errorObj = new HttpResult<T>();
      let serverErrorMessage = error.message;

      if (error && error.error && error.error.message) {
        serverErrorMessage = error.error.message;
      }

      errorObj.data = error.error;
      errorObj.errorMessage = serverErrorMessage;
      errorObj.status = error.status;
      errorObj.isSuccess = false;
      errorObj.friendlyMessage = serverErrorMessage;
      errorObj.headers = error.headers;
      errorObj.statusText = error.statusText;
      
      /**handle unauthorized response code
       * show an alert and then log out to clear token
      */
      if(error.status === 401){
        
        this.translateService.get("Your session has expired. Please sign in again").subscribe(
          (translatedString) => {
            errorObj.errorMessage = translatedString;
            this.notifyService.notifyError(errorObj);
            this.router.navigate([AppConstants.routePaths.logout]);    
          });
      }
      return throwError(errorObj);
    }

    private handleSuccess<T>(param: any): HttpResult<T> {

      const respObj = new HttpResult<T>();
      const data = respObj.data;
      respObj.data = param.body;
      respObj.isSuccess = true;
      respObj.errorMessage = undefined;
      respObj.headers = param.headers;
      respObj.statusText = param.statusText;
      respObj.status = param.status;
      respObj.ok = param.ok;
      respObj.url = param.url;

      // checking if the server has send any custom message in the body response
      if (param.body) {
      const customMessage = Object.keys(param.body).find(f => f === 'message');
      if (customMessage) {
      respObj.errorMessage = customMessage;
      }
    }

      return respObj;
    }

    private getRequestOptions(reqParams: RequestOptions) {
      if (!reqParams) {
        return {
          observe: ObserveResponse.Response as any ,
          responseType: HttpResponseType.JSON as any
        };
      }
      return {
          headers: reqParams.headers,
          observe: reqParams.observe as any ,
          params: reqParams.urlParams,
          responseType: reqParams.responseType as any,
          reportProgress: reqParams.reportProgress
        };

      }
}
