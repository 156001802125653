import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppInjectorService {

  private static injector: Injector;
  constructor() { }

  static getInjector() {
    return AppInjectorService.injector;
  }

  static setCurrentInjector(injector: Injector) {
    AppInjectorService.injector = injector;
  }
}
