import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResult } from '../../helpers/http-result';
import { UserModel } from '../../models/user/user-model';
import { AppConstantService } from '../app-constant.service';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpService {

  constructor(private appService: AppConstantService
              ) {
    super();
   }

  getUsers(): Observable<HttpResult<UserModel[]>> {
    return this.get<UserModel[]>(this.appService.apiPath.users);
  }

  updateUser(user: UserModel): Observable<HttpResult<UserModel>> {
    return this.put<UserModel>(this.appService.apiPath.users, user);
  }

  getCurrentUser(): Observable<HttpResult<UserModel>> {
    return this.get<UserModel>(this.appService.apiPath.users + '/CurrentUser');
  }
}