import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResult } from '../../helpers/http-result';
import { AudioTypeModel } from '../../models/audio-type-model';
import { AppConstantService } from '../app-constant.service';
import { HttpService } from '../http.service';
import { ApiResponse } from '../../models/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class AudioTypesService extends HttpService {
  constructor(private appService: AppConstantService) {
    super();
  }

  getAudioTypes(): Observable<HttpResult<ApiResponse<AudioTypeModel[]>>> {
    return this.get<ApiResponse<AudioTypeModel[]>>(
      this.appService.apiPath.audioTypes
    );
  }
}
