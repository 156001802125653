import { Component, NgModule } from '@angular/core';
import { AppConstants } from '../../../app-constants';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent { 

   constructor() {

   }
  get currentYear() {
    return new Date().getFullYear();
  }

  get version() {
    return environment.appVersion;
  }
}

@NgModule({
  declarations: [ FooterComponent ],
  exports: [ FooterComponent ]
})
export class FooterModule { }
