import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxCalendarComponent, DxDropDownBoxComponent, DxTreeListComponent, DxValidatorComponent } from 'devextreme-angular';
import { DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import dxTreeView, { dxTreeViewNode } from 'devextreme/ui/tree_view';
import { isEmpty } from 'rxjs/operators';
import DataSource from "devextreme/data/data_source";
import { TreeViewModel } from 'src/app/core/models/ui/tree-view-model';
import dxTreeList from 'devextreme/ui/tree_list';
import { DxiValidationRuleComponent, DxoPagerComponent, DxoValidationComponent } from 'devextreme-angular/ui/nested';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslationService } from '../../services';

@Component({
  selector: 'app-multi-select-tree-view-drop-down',
  templateUrl: './multi-select-tree-view-drop-down.component.html',
  styleUrls: ['./multi-select-tree-view-drop-down.component.scss']
})
export class MultiSelectTreeViewDropDownComponent implements OnInit, OnChanges {
  @Input()
  selectionMode: 'multiple' | 'single' = 'multiple'
  @Input()
  showCheckBoxesMode: 'none' | 'normal' | 'selectAll' = 'selectAll'
  @Input()
  showPaging: boolean = true;
  @Input()
  pageSize: number = 10;
  @Input()
  startPageIndex: number = 0;
  @Input()
  allowedPages: number[] = [5, 10, 15];
  @Input()
  dataSource: TreeViewModel[];
  @Input()
  dropDownSelection: TreeViewModel[];
  @Input()
  dataStructure: 'plain' | 'tree' = 'plain'
  @Input()
  enableSelectAll: boolean = true;
  @Input()
  headerText: string = this.translation.translateSingle(marker('Select All'));
  @Input()
  enableRequiredValidation = false;
  @Input()
  requiredValidationMessage = 'required';
  @Input()
  condition: any;
  @Input()
  opened: boolean = false;
  @Input()
  focusedRowEnabled = true;
  @Output()
  onInitialized = new EventEmitter();
  // width has to be 260 and above otherwise the drop down lookup pager will get squashed for some of the themes
  @Input()
  width: number;
  @Input()
  sortBy: 'id' | 'name' = 'name'
  // Output event emitter
  @Output()
  dropDownSelectionChange = new EventEmitter<TreeViewModel[]>()
  @Output()
  dataSourceChange = new EventEmitter<TreeViewModel[]>();

  @ViewChild(DxTreeListComponent, { static: false }) treeView: DxTreeListComponent;
  @ViewChild(DxDropDownBoxComponent, { static: false }) dropDownBox: DxDropDownBoxComponent;
  @ViewChild(DxValidatorComponent, { static: false }) dxValidator: DxValidatorComponent;



  treeDataSource: any;
  selectedTexts: string[];
  searchTerm = '';
  validate = false;
  borderStyle = '';
  callbacks = [];
  showValidationMessage = false;
  isDropwDownFocused = false;
  validationError = undefined;

  constructor(private translation: TranslationService) { }

  validationAdapter =  {
      getValue: () => {
          return this.getSelectedItems().length > 0 ? true : false;
      },
      applyValidationResults: (e) => {
          this.borderStyle = e.isValid ? "none" : "dx-invalid";
          this.showValidationMessage = !e.isValid;
      },
      validationRequestsCallbacks: this.callbacks
  }


  ngOnInit() {
  }

  revalidate() {
    this.callbacks.forEach(func => func());
  }



  onDropDownInitialised(eventArgs) {
    this.onInitialized.emit(eventArgs);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let dataSourceCurrentVal: TreeViewModel[] = changes?.dataSource?.currentValue;
    let dropDownSelectionCurrentVal: TreeViewModel[] = changes?.dropDownSelection?.currentValue;
    if (dataSourceCurrentVal?.length) {
      if(!changes?.sortBy || changes?.sortBy?.currentValue === 'name'){
        this.dataSource = dataSourceCurrentVal.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
      }
      if (this.dropDownBox?.instance) {
        this.dropDownBox?.instance?.repaint();
      }
      this.setSelectedText();
      this.showValidationMessage = false;
    }

    if (dropDownSelectionCurrentVal) {
      this.dropDownSelection = dropDownSelectionCurrentVal;
      this.setTreeViewSelestedValues(dropDownSelectionCurrentVal);
      this.setSelectedText();
    }
    this.borderStyle = 'none';
    if (changes?.opened?.currentValue) {
      this.opened = changes.opened.currentValue;
    }

  }


  onSelectionChange(e) {
    if (!e.row?.data) return;
    let focusedModel = (e.row.data as TreeViewModel);
    if (!focusedModel?.isChecked) {
      this.treeView.instance.selectRows([+focusedModel.id], true)
    }
    else {
      this.treeView.instance.deselectRows([+focusedModel.id])
    }
  }

  treeView_SelectionChanged(eventParam : {component: dxTreeList, selectedRowsData: TreeViewModel[], selectedRowKeys: number[], currentDeselectedRowKeys: number[], currentSelectedRowKeys: number[]}) {
    this.selectedTexts = [];   
    this.dataSource.forEach(f => {
      f.isChecked = eventParam.selectedRowKeys.includes(+f.id);
      if (f.isChecked) {
        this.selectedTexts.push(f.name);
      }
    });

    this.dropDownSelectionChange.emit(eventParam.selectedRowsData);
    this.dataSourceChange.emit(this.dataSource);
    this.revalidate();
  }

  getSelectedItems() {
    let selectedItems = new Array<TreeViewModel>();
    if (this.dataSource?.length) {
      selectedItems = this.dataSource.filter(f => f.isChecked)
    }
    return selectedItems;
  }

  setSelectedText() {
    let selectedItems = this.getSelectedItems();
    this.selectedTexts = selectedItems?.length ? selectedItems.map(m => m.name) : [''];
  }

  setTreeViewSelestedValues(selectedValues: TreeViewModel[]) {
    if (!selectedValues?.length) return;

    this.dataSource.forEach(f => {
        f.isChecked = selectedValues.some(s => s.id === f.id);
    });
  }

  resetAll() {
    if (!this.treeView) return;
    this.treeView.instance.deselectAll();
    this.dropDownSelectionChange.emit(new Array<TreeViewModel>())
  }

  onDropDownValueChange(values: string[]) {
    if (!values?.length) {
        this.resetAll();
    }
  }


  syncTreeViewSelection(e?: any) {
    let component: dxTreeList = (e && e.component) || (this.treeView && this.treeView.instance);

    if (!component) return;

    let selectedValues = this.getSelectedItems();

    if (!selectedValues?.length) {
       // component.deselectAll()
    }

    if (selectedValues?.length) {
       let selectedKeys = selectedValues.map(m => m.id);
       component.selectRows(selectedKeys, true);
       this.setSelectedText();
    }
 }
}
