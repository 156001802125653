<app-container title="{{'Profile'|translate}}">
  <app-content>
   <div class="col-lg-3 col-12 mt-4">
    <dx-form id="form" 
        [labelLocation]="'top'"  
        [showColonAfterLabel]="true">
        <dxi-item [label]="{text: 'Select Theme'|translate}" [cssClass]="'form-control'">
          <div *dxTemplate="let themeLookup of 'themeLookupTemplate'">
            <app-look-up [dataSource]="themes" [value]="currentTheme.id" (selectionChange)="onThemeChanged($event)"></app-look-up>
          </div>
        </dxi-item>
       <dxi-item [cssClass]="'form-control'">
        <div *dxTemplate="let btnStandard of 'btnStandardTemplate'">
          <dx-button icon="clearformat" text="{{'Reset'|translate}}" [type]="'default'" (onClick)="resetTheme()"></dx-button>
         </div>
       </dxi-item>
      </dx-form>
    </div>
  </app-content>  
</app-container>
