import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EntityAction } from '../enums/entity-action.enum';
import { EntityArea } from '../enums/entity-area.enum';
import { AuthorizationService } from './authorization.service';


/**
 * Action guard will test if an AllowedAction area has GET permissions
 * If it does, the area can be viewed. If not, it will not load.
 */
@Injectable({
  providedIn: 'root'
})
export class ActionGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let entityArea:EntityArea =  next.data.entityArea;
      let canView  = this.authService.hasAccess(entityArea,EntityAction.Read);
      return canView;
  }

  constructor(private authService: AuthorizationService){

  }
  
}
