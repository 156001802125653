

<app-container title="{{'Users'|translate}}">
  <app-content>
    <dx-data-grid
  [dataSource]="userData"
  [showBorders]="true"
  [focusedRowEnabled]="true"
  [focusedRowKey]="focusedRowKey"
  [focusedRowIndex]="0"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="false"
  keyExpr="userId"
  [loadPanel]="{enabled:true, text: 'Loading...'|translate}"
  noDataText="{{'No data' | translate}}"
  [rowAlternationEnabled]="true"
  (onContentReady)="onContentReady($event)"
  (onRowInserting)="onRowInserting($event)"
  (onRowRemoved)="onRowRemoved($event)"
  (onRowUpdated)="onRowUpdated($event)"
  >

  <dxo-editing mode="form"
    [allowAdding]="false | canAddPermission : entityArea"
    [allowDeleting]="checkDeleteAccess"
    [allowUpdating]="checkUpdateAccess"
    [selectTextOnEditStart]="true"
    [useIcons]="true"
    >
    <dxo-form [colCount]="2">
      <dxi-item itemType="group" [colSpan]="1">

        <dxi-item dataField="userName" [isRequired]="true">
          <dxi-validation-rule message="{{'Username must be in the form of an email address.'|translate}}" type="email"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="firstName" [isRequired]="true">
        </dxi-item>

        <dxi-item dataField="lastName" [isRequired]="true">
        </dxi-item>

      </dxi-item>

      <dxi-item itemType="group" [colSpan]="1">

        <dxi-item dataField="email" [isRequired]="true">
          <dxi-validation-rule type="email"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="companyUuid" [isRequired]="true"></dxi-item>

        <dxi-item dataField="role" [editorOptions]="{ disabled: disableEdit }"></dxi-item>

      </dxi-item>
    </dxo-form>
  </dxo-editing>

  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager infoText="{{'Page {0} of {1} ({2} items)'| translate}}" [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [operationDescriptions]="operationDescriptions" resetOperationText="{{'Reset' | translate}}" [visible]="true"></dxo-filter-row>

  <dxi-column
    dataField="userName"
    caption="{{'User Name'|translate}}"
    sortOrder="asc">
  </dxi-column>
  <dxi-column
    dataField="firstName"
    caption="{{'First Name'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="lastName"
    caption="{{'Last Name'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="email"
    caption="{{'Email'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="companyUuid"
    caption="{{'Company'|translate}}">
    <dxo-lookup [dataSource]="companies"
      valueExpr="companyUuid"
      displayExpr="name">
    </dxo-lookup>
  </dxi-column>
  <dxi-column
    dataField="role"
    caption="{{'Role'|translate}}">
    <dxo-lookup [dataSource]="userRoles"
      valueExpr="id"
      displayExpr="name">
    </dxo-lookup>
  </dxi-column>
  <dxi-column
    dataField="status"
    caption="{{'Status'|translate}}">
  </dxi-column>
</dx-data-grid>
  </app-content>
</app-container>