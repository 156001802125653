import { DemographicFilterModel } from 'src/app/core/models/demographic/demographic-filter-model';
import { DemographicFilterJoinType } from '../../enums/demographic-filter-join-type.enum';

export class DemographicFilterGroupModel extends DemographicFilterModel {

        $type = 'AquiraPlanner_APIModels.Models.Demographic.DemographicFilterGroupModel, AquiraPlanner_APIModels';

        /// <summary>
        /// The joint type
        /// </summary>
        public  joinType: DemographicFilterJoinType;

        public filters: DemographicFilterModel[];

}
