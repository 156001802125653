import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserModel } from 'src/app/core/models/user/user-model';
import { AuthorizationService } from '../../../core/services/authorization.service';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title: string;
  
  userEmail: string;
 
  private _userModel: UserModel;
  
  disposeSubscription$ = new Subject<boolean>();
  authService: AuthorizationService;
  
  userMenuItems = [{
    text: marker('Profile'),
    path: '/profile',
    icon: 'user',
    onClick: () => {
     this.router.navigate(['/profile']);
    }
  }, 
  {
    text: marker('Logout'),
    icon: 'runner',
    onClick: () => {
      this.router.navigate(['pages/logout']);
    }
  }
];

  constructor(
    private router: Router, 
    authService : AuthorizationService,
    private translate: TranslateService
    ) { 
      this.authService = authService;
  }

  ngOnInit(): void {
    this.userMenuItems.forEach((item)=>{
      this.translate.get(item.text).subscribe((result)=>{
        item.text = result;
      })
    });

    this.authService.userModelSetNotification$
      .pipe(
        takeUntil(this.disposeSubscription$)
        )
      .subscribe({
        next: (userSession) => {
          this.userModel = userSession.userModel; 
        },
        error: err => console.log('An error has occurred ' + err)
       }
      )
  }

  set userModel(userModel: UserModel){
    this._userModel = userModel;
    this.userEmail = userModel.email || 'unavailable';
  }

  get userModel():UserModel{
    return this._userModel;
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
