<app-container title="{{'Surveys'|translate}}">
  <app-content>
  <dx-file-uploader
  selectButtonText=""
  labelText=""
  accept=".xml"
  multiple="true"
  name="formFile"
  (onContentReady)="onFileUploaderContentReady($event)"
  (onUploaded)="onUploaded($event)"
  (onUploadError)="onUploadError($event)"
  [uploadHeaders]="headers"
  [uploadUrl]="apiUrl"
  [(value)]="value"
  [visible]="false | canAddPermission : entityArea"
  >
  </dx-file-uploader>
  
<dx-data-grid #surveyGrid
  [columnAutoWidth]="true"
  [columnHidingEnabled]="false"
  [dataSource]="surveys"
  [focusedRowEnabled]="true"
  [focusedRowIndex]="0"
  [focusedRowKey]="focusedRowKey"
  keyExpr="surveyCode"
  [loadPanel]="{enabled:true, text: 'Loading...'|translate}"
  noDataText="{{'No data' | translate}}"
  (onContentReady)="onDataGridContentReady($event)"
  (onEditingStart)="onEditingStart($event)"
  (onRowRemoving)="onRowRemoving($event)"
  [rowAlternationEnabled]="true"
  [showBorders]="true"
  [showColumnLines]="true"
  >

  <dxo-editing mode="form"
  [allowDeleting]="checkDeleteAccess"
  [allowUpdating]="checkUpdateAccess"
  [selectTextOnEditStart]="true"
  [texts]="editingTexts"
  [useIcons]="true"
  >
  <dxo-form colCount="1" [scrollingEnabled]="true">
    <dxi-item itemType="tabbed">
      <dxi-tab title="{{'General'|translate}}">
        <dxi-item dataField="surveyCode" [editorOptions]="{ disabled: true }"></dxi-item>
        <dxi-item dataField="description" [editorOptions]="{ disabled: true }"></dxi-item>
        <dxi-item dataField="marketId" [editorOptions]="{ disabled: true }"></dxi-item>
        <dxi-item dataField="year" [editorOptions]="{ disabled: true }"></dxi-item>
        <dxi-item dataField="weekNumber" [editorOptions]="{ disabled: true }"></dxi-item>
        <dxi-item dataField="surveyNumber" [editorOptions]="{ disabled: true }"></dxi-item>
        <dxi-item dataField="startDate" [editorOptions]="{ disabled: true, showDropDownButton: false }"></dxi-item>
        <dxi-item dataField="endDate" [editorOptions]="{ disabled: true, showDropDownButton: false }"></dxi-item>
      </dxi-tab>

      <dxi-tab title="{{'Stations'|translate}}">
        <dxi-item name="stations">
          <div *dxTemplate>          
            <dx-data-grid 
                [dataSource]="survey.stations"
                [loadPanel]="{enabled:true, text: 'Loading...'|translate}"
                noDataText="{{'No data' | translate}}"
                [showBorders]="true"
                [showColumnLines]="true">
                    <dxi-column dataField="stationId" caption="{{'Station ID'|translate}}"></dxi-column>
                    <dxi-column dataField="marketId" caption="{{'Market ID'|translate}}"></dxi-column>
                    <dxi-column dataField="shortDescription" caption="{{'Short Description'|translate}}"></dxi-column>
                    <dxi-column dataField="longDescription" caption="{{'Long Description'|translate}}"></dxi-column>
                    <dxi-column dataField="commercialStation" caption="{{'Commercial Station'|translate}}"></dxi-column>
                    <dxi-column dataField="sequence" caption="{{'Sequence'|translate}}"></dxi-column>
            </dx-data-grid>
          </div>
        </dxi-item>
      </dxi-tab>

      <dxi-tab title="{{'Program Events'|translate}}">
        <dxi-item name="programEvents">
          <div *dxTemplate>
            <dx-data-grid 
            [dataSource]="survey.programEvents"
            [loadPanel]="{enabled:true, text: 'Loading...'|translate}"
            noDataText="{{'No data' | translate}}"
            [showBorders]="true"
            [showColumnLines]="true">
                <dxi-column dataField="surveyCode" caption="{{'Survey Code'|translate}}"></dxi-column>
                <dxi-column dataField="stationId" caption="{{'Station ID'|translate}}"></dxi-column>
                <dxi-column dataField="programDate" caption="{{'Program Date'|translate}}" dataType="date"></dxi-column>
                <dxi-column dataField="programStartTime" caption="{{'Program Start Time'|translate}}"></dxi-column>
                <dxi-column dataField="programDuration" caption="{{'Program Duration'|translate}}"></dxi-column>
                <dxi-column dataField="programTypeId" caption="{{'Program Type ID'|translate}}"></dxi-column>
                <dxi-column dataField="programDescription" caption="{{'Program Description'|translate}}"></dxi-column>
                <dxi-column dataField="startQuarterHour" caption="{{'Start Quarter Hour'|translate}}"></dxi-column>
                <dxi-column dataField="endQuarterHour" caption="{{'End Quarter Hour'|translate}}"></dxi-column> 
            </dx-data-grid>
          </div>
        </dxi-item>
      </dxi-tab>
    </dxi-item>
  </dxo-form>
  </dxo-editing>

  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager infoText="{{'Page {0} of {1} ({2} items)'| translate}}" [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row betweenEndText="{{'End'|translate}}" betweenStartText="{{'Start' | translate}}" [operationDescriptions]="operationDescriptions" resetOperationText="{{'Reset' | translate}}" [visible]="true"></dxo-filter-row>

  <dxi-column
    dataField="surveyCode"
    caption="{{'Survey Code'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="description"
    caption="{{'Description'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="marketId"
    caption="{{'Market ID'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="year"
    caption="{{'Year'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="weekNumber"
    caption="{{'Week Number'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="surveyNumber"
    caption="{{'Survey Number'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="startDate"
    dataType="date"
    format="dd/MM/yyyy"
    caption="{{'Start Date'|translate}}">
  </dxi-column>
  <dxi-column
    dataField="endDate"
    dataType="date"
    format="dd/MM/yyyy"
    caption="{{'End Date'|translate}}">
  </dxi-column>
</dx-data-grid>
</app-content>
</app-container>