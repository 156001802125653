import { Injectable } from '@angular/core';
import { AppConstantService } from '../app-constant.service';
import { HttpService } from '../http.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { GetTokenResponse } from '../../models/user/get-token-response.model';
import { ObserveResponse } from '../../enums/observe-response.enum';
import { HttpResponseType } from '../../enums/http-response-type.enum';
import { map } from 'rxjs/operators';
import { HttpResult } from '../../helpers/http-result';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/app/app-constants';
import { NotifyService } from '../notify.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/internal/operators/catchError';
@Injectable({
  providedIn: 'root',
})
export class SecurityApiService {
  constructor(
    private appService: AppConstantService,
    private httpClient: HttpClient,
    private translateService: TranslateService,
    private notifyService: NotifyService,
    private router: Router
  ) {}

  getTokenById(tokenJti: string, urlParams?: any) {
    const tokensEndpoint = '/tokens';

    return this.httpClient
      .get<GetTokenResponse>(
        `${this.appService.securityApiUrl}${tokensEndpoint}/${tokenJti}`,
        {
          params: new HttpParams({ fromObject: urlParams }),
          observe: ObserveResponse.Response as any,
          responseType: HttpResponseType.JSON as any,
        }
      )
      .pipe(
        map((resp) => this.handleSuccess<GetTokenResponse>(resp)),
        catchError((error) => this.handleError<GetTokenResponse>(error))
      );
  }

  private handleSuccess<T>(param: any): HttpResult<T> {
    const respObj = new HttpResult<T>();
    const data = respObj.data;
    respObj.data = param.body;
    respObj.isSuccess = true;
    respObj.errorMessage = undefined;
    respObj.headers = param.headers;
    respObj.statusText = param.statusText;
    respObj.status = param.status;
    respObj.ok = param.ok;
    respObj.url = param.url;

    // checking if the server has send any custom message in the body response
    if (param.body) {
      const customMessage = Object.keys(param.body).find(
        (f) => f === 'message'
      );
      if (customMessage) {
        respObj.errorMessage = customMessage;
      }
    }

    return respObj;
  }

  private handleError<T>(error: HttpErrorResponse): Observable<HttpResult<T>> {
    const errorObj = new HttpResult<T>();
    let serverErrorMessage = error.message;

    if (error && error.error && error.error.message) {
      serverErrorMessage = error.error.message;
    }

    errorObj.data = error.error;
    errorObj.errorMessage = serverErrorMessage;
    errorObj.status = error.status;
    errorObj.isSuccess = false;
    errorObj.friendlyMessage = serverErrorMessage;
    errorObj.headers = error.headers;
    errorObj.statusText = error.statusText;

    /**handle unauthorized response code
     * show an alert and then log out to clear token
     */
    if (error.status === 401) {
      this.translateService
        .get('Your session has expired. Please sign in again')
        .subscribe((translatedString) => {
          errorObj.errorMessage = translatedString;
          this.notifyService.notifyError(errorObj);
          this.router.navigate([AppConstants.routePaths.logout]);
        });
    }
    return throwError(errorObj);
  }
}
