import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpResult } from '../../helpers/http-result';
import { SurveyLookupModel } from '../../models/survey-lookup-model';
import { SurveyModel } from '../../models/survey-model';
import { HttpService } from '../http.service';
import { ApiResponse } from '../../models/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class SurveysService extends HttpService {
  constructor() {
    super();
  }

  getSurveyLookup(): Observable<HttpResult<ApiResponse<SurveyLookupModel[]>>> {
    return this.get('SurveyLookups');
  }

  getSurveyByCode(surveyCode: string) {
    let requestUrl = `Surveys/${surveyCode}`;
    return this.get<ApiResponse<SurveyModel>>(requestUrl);
  }
}
