<dx-toast
type="custom"
[(visible)]="notifyModel.notify"
[displayTime]="notifyModel.displayTime"
[contentTemplate]="notifyModel.type"
[position] = "position"
[hoverStateEnabled] = "true"
(mouseenter)="toaster_mouseOver()"
(mouseleave)="toaster_mouseOut()"
>

<app-notify-template [tempClass]="cssNotify.success" [templateType]="'success'" [templateIcon]="'fa fa-check-square-o type'" [(notifyModel)]="notifyModel" ></app-notify-template>

<app-notify-template [tempClass]="cssNotify.info" [templateType]="'info'" [templateIcon]="'fa fa-info-circle type'" [(notifyModel)]="notifyModel" ></app-notify-template>

<app-notify-template [tempClass]="cssNotify.warning" [templateType]="'warning'" [templateIcon]="'fa fa-warning type'" [(notifyModel)]="notifyModel" ></app-notify-template>

<app-notify-template [tempClass]="cssNotify.error" [templateType]="'error'" [templateIcon]="'fa fa-window-close type'" [(notifyModel)]="notifyModel" ></app-notify-template>

</dx-toast>
