import { Component, OnInit } from '@angular/core';
import { MarketService } from 'src/app/core/services/market/markets.service';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.scss']
})
export class MarketsComponent implements OnInit {
  dataSource: any;
  operationDescriptions: any;

  constructor(private marketService: MarketService, private translation: TranslationService) {
  }

  async ngOnInit() {
    this.operationDescriptions = await this.translation.getOperationDescriptions();

    this.marketService.getMarkets().subscribe((res:any)=>{
      this.dataSource = res.data.entity
    });
  }

}