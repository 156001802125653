
export enum UserRole {
  StandardUsers = 0,
  SystemAdministrator = 1,
  CompanyAdministrator = 2,
  AdvancedUser = 3
}

export const USER_ROLE = {
  StandardUsers: 'Standard Users',
  SystemAdministrator: 'System Administrators',
  CompanyAdministrator: 'Company Administrators',
  AdvancedUser: 'Advanced Users'
};


