
import { NotifyType } from 'src/app/core/enums/notify-type.enum';

export class NotificationModel {

  type: NotifyType;
  notify: boolean;
  messages: string[] | string;
  title: string;
  displayTime: number;

  constructor() {
    this.notify = false;
  }
}