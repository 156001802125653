import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { AppConstants } from './app-constants';
import { EntityArea } from "./core/enums/entity-area.enum";

export const navigation = [
  {
    text: marker('Home'),
    path: `/${AppConstants.routePaths.home}`,
    icon: 'home'
  },
  {
    text: marker('Demographics'),
    path: `/${AppConstants.routePaths.demographics}`,
    icon: 'group',
    area: EntityArea.Demographic
  },
  {
    text: marker('Markets'),
    path: `/${AppConstants.routePaths.markets}`,
    icon: 'globe'
  },
  {
    text: marker('Places'),
    path: `/${AppConstants.routePaths.places}`,
    icon: 'airplane'
  },
  {
    text: marker('Surveys'),
    path: `/${AppConstants.routePaths.surveys}`,
    icon: 'activefolder',
    area: EntityArea.Survey
  },
  {
    text: marker('Variables'),
    path: `/${AppConstants.routePaths.variables}`,
    icon: 'variable'
  },
  {
    text: marker('Calculations'),
    path: `/${AppConstants.routePaths.calculations}`,
    icon: 'chart'
  },
  {
    text: marker('Profile'),
    path: `/${AppConstants.routePaths.profile}`,
    icon: 'user'
  },
  {
    text: marker('Logout'),
    path: `/${AppConstants.routePaths.logout}`,
    icon: 'runner'
  }
];