import { Component } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: [ './home.component.scss' ]
})

export class HomeComponent {
  welcomeMessage : string;
  rcsLogo = `${environment.baseHref}/assets/rcs.png`
  constructor(private authService : AuthorizationService, private translate: TranslateService) {

    //wrap this in a subscription
    if(this.authService.isAuthenticated()){
      this.translate.get(marker("Welcome back")).subscribe((result)=>{
        this.welcomeMessage = `${result} ${this.authService.userSession.userModel?.firstName || ''}`;
      });
    }
  }
}