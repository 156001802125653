
<app-container title="{{'Companies' | translate}}">
  <app-content>


  <dx-data-grid
  class="responsive-padding"
  [columnAutoWidth]="true"
  [columnHidingEnabled]="false"
  [dataSource]="dataSource"
  [focusedRowEnabled]="true"
  [focusedRowIndex]="0"
  [focusedRowKey]="focusedRowKey"
  keyExpr="companyUuid"
  [loadPanel]="{enabled:true, text: 'Loading...'|translate}"
  noDataText="{{'No data' | translate}}"
  (onContentReady)="onContentReady($event)"
  (onRowInserted)="onRowInserted($event)"
  (onRowInserting)="onRowInserting($event)"
  (onRowRemoved)="onRowRemoved($event)"
  (onRowUpdated)="onRowUpdated($event)"
  (onRowUpdating)="onRowUpdating($event)"
  [rowAlternationEnabled]="true"
  [showBorders]="true"
  [showColumnLines]="true">

  <dxo-editing mode="form"
    [allowAdding]="false | canAddPermission : entityArea"
    [allowDeleting]="checkDeleteAccess"
    [allowUpdating]="checkUpdateAccess"
    [selectTextOnEditStart]="true"
    [texts]="editingTexts"
    [useIcons]="true"
  ></dxo-editing>
  
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager infoText="{{'Page {0} of {1} ({2} items)'| translate}}" [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
  <dxo-filter-row [operationDescriptions]="operationDescriptions" resetOperationText="{{'Reset' | translate}}" [visible]="true"></dxo-filter-row>
  <dxo-sorting ascendingText="{{'Sort Ascending' | translate}}" clearText="{{'Clear Sorting'|translate}}" descendingText="{{'Sort Descending'|translate}}"></dxo-sorting>

  <dxi-column type="buttons" [visible]="checkUpdateAccess || checkDeleteAccess">
    <dxi-button name="edit"></dxi-button>
    <dxi-button name="delete" [onClick]="delete"></dxi-button>
  </dxi-column>

  <dxi-column
    dataField="name"
    caption="{{'Name' | translate}}"
    sortOrder="asc">
    <dxi-validation-rule 
      type="required" message="{{'Name may not be null or empty.'|translate}}"
    ></dxi-validation-rule>
  </dxi-column>
</dx-data-grid>
</app-content>
</app-container>


