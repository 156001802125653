import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CompanyLookupModel } from 'src/app/core/models/company/company-lookup-model';
import { DemographicLookupModel } from 'src/app/core/models/demographic/demographic-lookup-model';
import { DemographicModel } from 'src/app/core/models/demographic/demographic-model';
import { TreeViewModel } from 'src/app/core/models/ui/tree-view-model';
import { VariableModel } from 'src/app/core/models/variable-model';
import { CompaniesService } from 'src/app/core/services/companies/company.service';
import { DemographicsService } from 'src/app/core/services/demographics/demographics.service';
import { VariableService } from 'src/app/core/services/variable/variables.service';
import { DxFilterModelBuilder } from './presentationLogics/dx-filter-model-builder';
import { NotifyService } from 'src/app/core/services/notify.service';
import { HttpResult } from 'src/app/core/helpers/http-result';
import { NotifyType } from 'src/app/core/enums/notify-type.enum';
import { WebApiFilterModelBuilder } from './presentationLogics/web-Api-Filter-Model-Builder';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { DemographicFilterModel } from 'src/app/core/models/demographic/demographic-filter-model';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { UserModel } from 'src/app/core/models/user/user-model';
import { TranslationService } from 'src/app/shared/services';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import TreeView from "devextreme/ui/tree_view";
import { EntityAction } from 'src/app/core/enums/entity-action.enum';
import { EntityArea } from 'src/app/core/enums/entity-area.enum';
import { CheckActions } from 'src/app/core/interface/check-actions';
import { UserRole } from 'src/app/core/enums/user-role.enum';

@Component({
  selector:  'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls:  ['./demographics.component.scss']
})
export class DemographicsComponent implements OnInit, OnDestroy, CheckActions {
  @Output()
  filterValuesChange: any = new EventEmitter<any>();
  customOperations: any;
  companies: CompanyLookupModel[];
  dataSource: DemographicLookupModel[];
  demographic: DemographicModel;
  dxModelBuilder: DxFilterModelBuilder;
  editingTexts: any;
  filterBuilderFields: VariableModel[];
  filterDropDownValues: any[];
  filterDropDownValuesTreeView: TreeViewModel[];
  filterModel: any[];
  filterValues = [];
  focusedRowKey: string;
  groupOperationDescriptions: any;
  _isEditing: boolean = false;
  operationDescriptions: any;
  selectAll: boolean = false;
  selectedFilterFieldsLookup: Array<VariableModel>;
  selectedValues: TreeViewModel[];
  user: UserModel;
  userSelectedFilterValuesTreeView: TreeViewModel[];
  variables: VariableModel[];
  webApiFilterModel: WebApiFilterModelBuilder;
  isDropDownOpened = false;
  entityArea : EntityArea = EntityArea.Demographic;
  
  
  constructor(private appService: AppConstantService,
              private authService : AuthorizationService,
              private companyService: CompaniesService, 
              private demographicService: DemographicsService,
              private notifyService: NotifyService,
              private translate: TranslateService,
              private translation: TranslationService,
              private variableService: VariableService) {
    this.checkDeleteAccess = this.checkDeleteAccess.bind(this);
    this.checkUpdateAccess = this.checkUpdateAccess.bind(this);
  }

  checkUpdateAccess(e){
      /**first test base access from allowedactions
       * if update is available, retest as it is only available for SysAdmins for global demographics
       */
      let canUpdate = this.authService.hasAccess(EntityArea.Demographic,EntityAction.Update);
      if(canUpdate){
        /**only true is it is a non global for non sysAdmins
         * true for all for sysAdmins
         */
         canUpdate = !e.row.data.isGlobal ||
         this.authService.isAdmin();
      }
      return canUpdate;
  }

  checkDeleteAccess(e){
      /**first test base access from allowedactions
       * if delete is available, delete is only available for SysAdmins for global demographics
       */
      let canDelete = this.authService.hasAccess(EntityArea.Demographic,EntityAction.Delete);
      if(canDelete){
        /**only true is it is a non global for non sysAdmins
         * true for all for sysAdmins
         */
        canDelete = !e.row.data.isGlobal ||
        this.authService.isAdmin();
      }
      return canDelete;
  }

  dataGridOnContentReady(e){
    e.component.columnOption("command:edit", "visibleIndex", -1);
  }

  getVariableById(variableId:number): VariableModel{
    return this.variables.find(v=>v.variableId === variableId);
  }

  getVariables(): void {
    this.variableService.getVariables()
      .subscribe(
        (resp) => {
           if (!resp || resp.data.entity.length === 0) {
              return;
           }          
           //removing duplicated description and sorting
          this.variables = resp.data.entity.filter((item, index, self) => index === self.findIndex(f => f.description === item.description))
                               .sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase() ? 1 : -1);        
          this.variables.forEach(v => v["filterOperations"]=["contains"]);   
          this.webApiFilterModel = new WebApiFilterModelBuilder(this.variables);
        })
  }

  async ngOnInit() {
    this.configureDxTreeView(true);
    this.translate.get(marker('In List')).subscribe((result)=>{
      this.customOperations = [{
        name: "contains",
        caption: result,
        icon: "check",
        editorTemplate: "betweenTemplate",
        calculateFilterExpression(filterValue: any, field: any) {
            return filterValue && filterValue.length
                && Array.prototype.concat.apply([], filterValue.map(function(value) {
                    return [[field.dataField, "=", value], "or"];
                })).slice(0, -1);
        }
      }];
    })
    this.editingTexts = await this.translation.getEditingTexts();
    this.groupOperationDescriptions = await this.translation.getGroupOperationDescriptions();
    this.operationDescriptions = await this.translation.getOperationDescriptions();    
    if(this.authService.isAuthenticated()){
      this.user = this.authService.userSession.userModel;
    }

    this.demographicService.getDemographicLookup().subscribe( s => {
      this.dataSource = s.data.entity;
    });
    this.variables = new Array<VariableModel>();
    this.getVariables();
    this.selectedFilterFieldsLookup = new Array<VariableModel>();
   }

  ngOnDestroy(): void {
    this.configureDxTreeView(false);
  }

  configureDxTreeView(searchState : Boolean) : void{
    TreeView.defaultOptions({ 
      device: { deviceType: "desktop" },
      options: {
          searchEnabled: searchState,
        }
    });  
  }

  demoChkValueSelect_checked(currentValue: TreeViewModel[], condition) {
    this.userSelectedFilterValuesTreeView = currentValue;

    condition.setValue(this.userSelectedFilterValuesTreeView.map(m => m.name));
  }
  
  dxFilterBuilder_onValueChange(changedValue) {
    this.filterValues = changedValue.component._model;
    this.filterValuesChange.emit(this.filterValues);
  }

  handleFilter(){
    this.demographic.filter = this.webApiFilterModel.getFilterBuilderValues(this.filterValues);
  }

  isFilter(filter) {
    if (filter && filter.$type && filter.$type.includes('DemographicFilterVariableModel') && filter.variableId) {
      return true;
    }
    return false;
  }

  trackFilterVariables(index: number, item: VariableModel ) {
    return item.variableId;
  }

  isGroup(group) {
    if (group && group.$type && group.$type.includes('DemographicFilterGroupModel') && group.joinType) {
      return true;
    }
    return false;
  }

  onEditorPreparing(e){
    if(e.parentType === "dataRow" && e.dataField === "isGlobal") {
     /** Edits are now all readOnly for IsGlobal field
      * regardless of role
      * only sysAdmins can check the field when its new
      * onEditorPrepared runs for the add and edit form
      */
     e.editorOptions.disabled =  !this.authService.isAdmin() || this._isEditing ;
    }
  }

  onEditCanceled(e){
    this._isEditing = false;
  }

  onEditingStart(e){
      this._isEditing = true;
      this.demographicService.getDemographicByUuid(e.key).subscribe( s => {
      this.demographic = s.data.entity;

      //The original DxFilterModelBuilder seems to be a wrapper around all variables
      this.dxModelBuilder = new DxFilterModelBuilder(this.variables);
      this.filterValues = this.dxModelBuilder.getDxFilterModel(this.demographic.filter);
      this.filterModel = this.filterValues;//filterModel and filterValues need to be separated, otherwise after users selected value, they cannot change variable anymore.
      this.selectedFilterFieldsLookup = this.dxModelBuilder.getFilterBuilderVariables(this.demographic.filter);
      this.filterBuilderFields = this.selectedFilterFieldsLookup;
    });
  }

  onFitlerOutputValues(eventArgs) {
    this.filterValues = eventArgs;
  }

  onInitialised(eventArgs) {
    const variableId = eventArgs.field.editorOptions.data.variableId;   
    this.filterDropDownValues = this.variables.find(v => v.variableId === variableId).values;
    this.filterDropDownValuesTreeView = this.filterDropDownValues.map(m => new TreeViewModel(m.valueId, m.description, false));
    let selVal: any[];
    this.userSelectedFilterValuesTreeView = [];
    this.isDropDownOpened = true;

    if (eventArgs.value) {
      selVal = eventArgs.value;
      this.filterDropDownValuesTreeView.forEach(v => {
        v.isChecked = selVal.some(s => s === v.name);
        if (v.isChecked) {
          this.userSelectedFilterValuesTreeView.push(v);
        }
      });
    } else {
      this.filterDropDownValuesTreeView.forEach(v => {
        v.isChecked = false;
      });
    }
  }

  onInitNewRow(e){
    this._isEditing = false;
    this.demographic = e.data;
    this.demographic.isGlobal = false;
    this.filterModel = undefined;
    this.filterValues = undefined;
  }

  onRowRemoved(e:any): void{
    this.appService.showLoadingNotification(true, this.translation.translateSingle(marker("Deleting demographic...")));
    this.demographicService.deleteByID(this.appService.apiPath.demographics, e.data.demographicUuid).subscribe(
      (resp) => {
        this.notifyService.notifySuccess(e.data.description + " " + this.translation.translateSingle(marker("deleted")), this.translation.translateSingle(marker("Success")));
      },
      (err: HttpResult<DemographicModel>) => {
        this.notifyService.notifyError(err);
      },
      () => {
        this.demographic = undefined;
        this.appService.showLoadingNotification(false);
      }
    );
  }

  onRowValidating(e){
    if(e.brokenRules.length){
      this.notifyService.notifyWarning(e.brokenRules.map((rule)=>rule.message), this.translation.translateSingle(marker("Validation")));
    }
  }

  onSaved(e){
    if(e.changes.length){
      this.demographic = e.changes[0].data;
    }

    if(this.demographic !== undefined){
      this.appService.showLoadingNotification(true, this.translation.translateSingle(marker("Saving demographic...")));
      this.handleFilter();
      this.setDates();

      if(this.demographic.isGlobal===false){
        this.demographic.organizationId = this.user.organizationId;
      }

      let funcName = this._isEditing ? 'update' : 'insert';

      this.demographicService[funcName](this.demographic)      
      .subscribe(
        (resp) => {
          this.notifyService.notifySuccess(this.translation.translateSingle(marker("Demographic saved")), this.translation.translateSingle(marker("Success")));
          this.focusedRowKey = resp.data.demographicUuid;
          this.finally(e.component);
        },
        (err: HttpResult<DemographicModel>) => {
          this.notifyService.showNotification(err.friendlyMessage , NotifyType.Error, err.statusText);
          this.finally(e.component);
        }
      )
    }
  }

  finally(component){
    this.appService.showLoadingNotification(false);
   
  }

  onSaving(e){
    if(e.changes.length > 0 && e.changes[0].type === "remove"){
      return;
    } else {
      if(e.changes.length > 0 && this.dataSource.find(d=>d.description===e.changes[0]?.data?.description)){
        this.notifyService.notifyWarning(this.translation.translateSingle(marker("Demographic with this name already exists.")), this.translation.translateSingle(marker("Validation")));
        e.cancel = true;
      }

      this.handleFilter();
      
      if(this.isGroup(this.demographic.filter) && this.demographic.filter.filters && this.demographic.filter.filters.length===0){
        this.notifyService.notifyWarning(this.translation.translateSingle(marker("At least one filter must be specified for a group filter.")), this.translation.translateSingle(marker("Validation")));
        e.cancel = true;
      }

      this.demographic.filter?.filters.forEach(f=>{
        if(this.isFilter(f) && f.valueIds.length <= 0){
          this.notifyService.notifyWarning(this.translation.translateSingle(marker("At least one value must be specified for a non-group Filter.")), this.translation.translateSingle(marker("Validation")));
          e.cancel = true;
      }});
    }
  }

  setDates(){
    this.demographic.startDate = new Date(1, 0, 1);
    this.demographic.endDate = new Date(9999, 11, 31);
    if (this.demographic.filter != null){
      let dates = this.calculateStartAndEndDates(this.demographic.filter, this.demographic.startDate, this.demographic.endDate);
      this.demographic.startDate = dates.startDate;
      this.demographic.endDate = dates.endDate;
    }

    this.dataSource.find(d=>d.demographicUuid === this.demographic.demographicUuid).startDate = new Date(this.demographic.startDate);
    this.dataSource.find(d=>d.demographicUuid === this.demographic.demographicUuid).endDate = new Date(this.demographic.endDate);
  }

  calculateStartAndEndDates(filter: DemographicFilterModel, startDate: Date, endDate: Date){
    if (filter.variableId > 0){
      let variable = this.variables.find(v => v.variableId == filter.variableId);
      if (variable != null){
        if (new Date(variable.startDate) > new Date(startDate)){
            startDate = variable.startDate;
        }
        if (new Date(variable.endDate) < new Date(endDate)){
            endDate = variable.endDate;
        }
      }
    } else if (filter.filters != null && filter.filters.length > 0){
      filter.filters.forEach(f=>{
        let dates = this.calculateStartAndEndDates(f, startDate, endDate)
        startDate = dates.startDate;
        endDate = dates.endDate;  
      });
    }
    return {startDate: startDate, endDate: endDate}
  }

  setValue(e, condition){
    for(let i=0;i<e.value.length;i++){
      if(e.previousValue.indexOf(e.value[i])<0){
        condition.field.lookup.dataSource.forEach(d=>{
          if(d.valueId===e.value[i]){
            e.value[i]=d.description;
          }
        })
      }
    }
  }
}