import { Component, OnInit } from '@angular/core';
import { DxButtonComponent } from 'devextreme-angular';

@Component({
  selector: 'app-indicator-button',
  templateUrl: './indicator-button.component.html',
  styleUrls: ['./indicator-button.component.scss']
})
export class IndicatorButtonComponent extends DxButtonComponent implements OnInit {

  ngOnInit() {
  }

}
