
<dx-drop-down-box
[(opened)]="canShow"
[(value)]="selectedValues"
(onInitialized)="onInitialised($event)"
[contentTemplate]="'template'"
[placeholder]="'Select Values'"
[disabled]="disabled"
[elementAttr]="{ style: {'overflow-y': 'scroll'}, disabled: disabled }"
[width]="900"
>
<div *dxTemplate="let it of 'template'" style="overflow-y: scroll;">
    <div class="d-block clearfix ml-4">
      <div class="d-inline" *ngIf="rangeSelection">
        <label>Specify range: </label>
        <span class="ml-2">  </span>
        <input class="num-box" [(ngModel)]="betweenMinVal" type="number" />
        <span>  -  </span>
        <input class="num-box" [(ngModel)]="betweenMaxVal" type="number" />
     </div>
      <dx-check-box class="ml-3" [(ngModel)]="chkSelectAll" text="{{'Select All'|translate}}" [value]="false" (valueChange)="chkSelectAll_checked($event)"  [name]="'Select All'"></dx-check-box>
      <label class="ml-4 lbl-icon" (click)="chkSelectAll_checked(false)"> <i class="fa fa-times icon-sm"></i> Unselect All</label>

      <div class="d-inline  ml-3" *ngIf="enableSearch">
        <input (keyup)="onSearchTextChange($event)"  class="search-text" placeholder="Search" type="text" />
      </div>
      <dx-button class="ml-3" #btn [text]="'Apply'"  (onClick)="saveFilterValuesChanges($event)" [type]="'success'" [useSubmitBehavior]="true"></dx-button>
    </div>
    <hr />
    <div class="d-block  mt-4">
      <div class="clearfix">
        <div class="mt-3 ml-4 float-left" style="width:220px;" *ngFor="let value of filterValues">
          <dx-check-box [text]="value.name" [value]="value.isChecked" (valueChange)="chkValueSelect_checked($event, value)"  [name]="value.name"></dx-check-box>
        </div>
      </div>
    </div>
  </div>
  <dx-validator>
      <dxi-validation-rule *ngIf="enableRequiredValidation" [type]="'required'" [message]="requiredValidationMessage" ></dxi-validation-rule>
  </dx-validator>
</dx-drop-down-box>
