import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpResult } from '../../helpers/http-result';
import { VariableModel } from '../../models/variable-model';
import { AppConstantService } from '../app-constant.service';
import { HttpService } from '../http.service';
import { ApiResponse } from '../../models/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class VariableService extends HttpService {
  constructor(private appService: AppConstantService) {
    super();
  }

  getVariables(): Observable<HttpResult<ApiResponse<VariableModel[]>>> {
    return this.get<ApiResponse<VariableModel[]>>(
      this.appService.apiPath.variables
    );
  }
}
