import { Component, OnInit, AfterViewInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { delay } from 'rxjs/operators';
import { NotifyService } from 'src/app/core/services/notify.service';
import { NotificationModel } from 'src/app/core/models/notification-model';
import { AppConstants } from 'src/app/app-constants';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, AfterViewInit {

  notifyModel: NotificationModel;
  // position = {
  //   my: 'top right',
  //   at: 'top right',
  //   //making the toaster to be 24% right from the screen and setting the top to 0
  //   offset: `${window.screenX - ((24 * window.screenX)/ 100)} 0`,
  //   of: window
  // };
  position = AppConstants.notifyOption.position;
  cssNotify = AppConstants.css.notify;
  templateType: string;

  cssSuccess = this.cssNotify.success;
  cssInfo = this.cssNotify.info;
  cssWarning = this.cssNotify.warning;
  cssError = this.cssNotify.error;


  constructor(private notify: NotifyService) {  }

  ngOnInit() {
    this.notifyModel = new NotificationModel();
    this.notifyModel.notify = false;
    this.notify.notify$
    .pipe(delay(0))
    .subscribe(nModel => this.showNotification(nModel));
  }

  ngAfterViewInit() {

  }


  showNotification(nModel: NotificationModel) {
    if (!nModel.displayTime) {
      nModel.displayTime = AppConstants.notifyDisplayTime;
    }
    this.notifyModel = nModel;
  }

  toaster_mouseOver() {
    this.notifyModel.notify = true;
    console.log('mouse over fired');
  }

  toaster_mouseOut() {
    this.notifyModel.notify = false;
  }


}
