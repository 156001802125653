import { Component, OnInit } from '@angular/core';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  iconPath : string; 
  farewell: string;

  constructor(
    private authService : AuthorizationService, 
    public globalService : AppConstantService) { 
    }

  ngOnInit() {
    this.iconPath = `${environment.rcsCdnUrl}/aquiraplannerau/images/AquiraPlanner_200x53-w_working.png`;
    this.authService.logOut();
    /*TODO: *use the token id when signing out*/
  }

}
