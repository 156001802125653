import { Injectable } from '@angular/core';
import { NotificationModel } from 'src/app/core/models/notification-model';
import { NotifyType } from 'src/app/core/enums/notify-type.enum';
import { BehaviorSubject } from 'rxjs';
import { HttpResult } from '../helpers/http-result';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor() { }

  notifyModel = new NotificationModel();

  notify$ = new BehaviorSubject(this.notifyModel);

  notifyWithModel(notification: NotificationModel) {
    this.notify$.next(notification);
  }

  showNotification( message: string[] | string, type: NotifyType, title?: string, displayTime?: number) {

      const notifyModel = new NotificationModel();
      notifyModel.type = type;
      notifyModel.messages = message.constructor === Array ? message as string[]: [message as string];
      notifyModel.displayTime = displayTime;
      notifyModel.notify = true;
      notifyModel.title = title;
      this.notify$.next(notifyModel);
  }

  notifyError(error: HttpResult<any>, title?: string, displayTime?: number) {
    this.showNotification(error.errorMessage, NotifyType.Error, title, displayTime);
  }

  notifySuccess(message: string, title?: string, displayTime?: number) {
    this.showNotification(message, NotifyType.Success, title, displayTime);
  }

  notifyInfo(message: string, title?: string, displayTime?: number) {
    this.showNotification(message, NotifyType.Info, title, displayTime);
  }

  notifyWarning(message: string, title?: string, displayTime?: number) {
    this.showNotification(message, NotifyType.Warning, title, displayTime);
  }

  notifyValidationError(message: string, displayTime?: number) {
    this.showNotification(message, NotifyType.Error, 'Validation Errors', displayTime);
  }

  notifyValidationErrors(message: string[], displayTime?: number) {
    this.showNotification(message, NotifyType.Error, 'Validation Errors', displayTime);
  }

  notifyErrorMessage(message: string, title?: string, displayTime?: number) {
    this.showNotification(message, NotifyType.Error, title, displayTime);
  }
}