import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { dxFormOptions } from 'devextreme/ui/form';
import { GridColumn } from 'src/app/core/models/ui/grid-column';
import { TranslationService } from '../../services';

/**
 * Wrap the devextreme gridview component with all the styles. So that we don't have to repeat it again and again.
 * It can be used for most of the simple case but for more customized scenarios which involves multiple nested components
 * and templates use the actual devextreme grid.
 */

@Component({
  selector: 'app-gridview',
  templateUrl: './gridview.component.html',
  styleUrls: ['./gridview.component.scss']
})
// Include any additional devextreme gridview properties you needed and expose it as input or output properites.
export class GridviewComponent implements OnInit, OnChanges {

@Input()
dataSource: any[]
@Input()
gridColumns: GridColumn[];
@Input()
pageSize = 20;
@Input()
allowedPageSizes=[10, 50, 100];
@Input()
columnAutoWidth = true;
@Input()
export = false;
@Input()
fileName: string = "DataGrid";
@Input()
columnChooser = false;
@Input()
groupPanel = false;
@Input()
columnMinWidth = 70;
@Input()
errorRowEnabled: boolean = true;
@Input()
focusedRowEnabled: boolean = true;
@Input()
focusedRowIndex: number = 0;
@Input()
hoverStateEnabled = true;
@Input()
keyExpr: string = "";
@Input()
allowColumnReordering = true;
@Input()
showColumnLines = true;
@Input()
showBorders = true;
@Input()
loadPanel = true;
@Input()
rowAlternationEnabled = true;
@Input()
editing: {
  allowAdding?: boolean;
  allowDeleting?: boolean | Function;
  allowUpdating?: boolean | Function; 
  form?: dxFormOptions;
  mode?: string;
  selectTextOnEditStart?: boolean;
  useIcons?: boolean;  
} = {};
@Input()
cssClass: string;
@Input()
columnHidingEnabled = false;
@Output()
onInitialized = new EventEmitter<DxDataGridComponent>();
@Output()
onContentReady = new EventEmitter();
@Output()
onEditCanceled = new EventEmitter();
@Output()
onEditingStart = new EventEmitter();
@Output()
onEditorPreparing = new EventEmitter();
@Output()
onInitNewRow = new EventEmitter();
@Output()
onRowRemoved = new EventEmitter();
@Output()
onSaved = new EventEmitter();
@Output()
onSaving = new EventEmitter();


@ViewChild(DxDataGridComponent) gridView: DxDataGridComponent 
  groupingTexts: any;
  operationDescriptions: any;

  constructor(private translation: TranslationService){}
  
  async ngOnInit() {
    this.groupingTexts = await this.translation.getGroupingTexts();
    this.operationDescriptions = await this.translation.getOperationDescriptions();
  }

  get gridViewInstance() {
    return this.gridView;
  }

  ngOnChanges(changes: SimpleChanges) {

    let dataSource = changes.dataSource?.currentValue;
    let gridColumns = changes.gridColumns?.currentValue as GridColumn[];

    if(dataSource?.length) {
      this.dataSource = [...dataSource]
    }

    if (gridColumns?.length) {
      this.gridColumns = [...gridColumns];
    }
  }

  onGridInitialized(eventParam) {
    this.onInitialized.emit(this.gridView);   
  }

onGridContentReady(eventParam) {
  this.onContentReady.emit(eventParam);
};

onGridEditCanceled(eventParam) {
  this.onEditCanceled.emit(eventParam);
};

onGridEditingStart(eventParam) {
  this.onEditingStart.emit(eventParam);
};

onGridEditorPreparing(eventParam) {
  this.onEditorPreparing.emit(eventParam);
};

onGridInitNewRow(eventParam) {
  this.onInitNewRow.emit(eventParam);
};

onGridRowRemoved(eventParam) {
  this.onRowRemoved.emit(eventParam);
};

onGridSaved(eventParam) {
  this.onSaved.emit(eventParam);
};

onGridSaving(eventParam) {
  this.onSaving.emit(eventParam);
};


}