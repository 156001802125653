import { DemographicFilterJoinType } from '../../enums/demographic-filter-join-type.enum';

export class DemographicFilterModel {

  joinType?: DemographicFilterJoinType;

  filters?: DemographicFilterModel[];

  variableId?: number;

  valueIds?: number[];

  constructor(init?: DemographicFilterModel){
    Object.assign(this, init);
  }
}
