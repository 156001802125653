import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { LoginFormComponent } from './shared/components';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DxDataGridModule, DxFormModule } from 'devextreme-angular';
import { DemographicsComponent } from './pages/demographics/demographics.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { MarketsComponent } from './pages/markets/markets.component';
import { PlacesComponent } from './pages/places/places.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { UsersComponent } from './pages/users/users.component';
import { VariablesComponent } from './pages/variables/variables.component';
import { CalculationsComponent } from './pages/calculations/calculations.component';

import { LogoutComponent } from './pages/logout/logout.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppConstants } from './app-constants';
import { ActionGuard } from './core/services/action.guard';
import { EntityArea } from './core/enums/entity-area.enum';
import { AuthorizationGuardService } from './core/services/authorization-guard.service';
import { LoginCallbackComponent } from './shared/components/login-callback/login-callback.component';

const routes: Routes = [
  {
    path: AppConstants.routePaths.logout,
    component: LogoutComponent,
    canActivate: [AuthorizationGuardService],
  },
  {
    path: AppConstants.routePaths.loginCallback,
    component: LoginCallbackComponent,
  },
  {
    path: AppConstants.routePaths.calculations,
    loadChildren: () =>
      import('../app/pages/calculations/calculations.module').then(
        (t) => t.CalculationsModule
      ),
    canActivate: [AuthorizationGuardService],
  },
  {
    path: AppConstants.routePaths.variables,
    component: VariablesComponent,
    canActivate: [AuthorizationGuardService],
  },
  {
    path: AppConstants.routePaths.surveys,
    component: SurveysComponent,
    canActivate: [AuthorizationGuardService, ActionGuard],
    data: {
      entityArea: EntityArea.Survey,
    },
  },
  {
    path: AppConstants.routePaths.places,
    component: PlacesComponent,
    canActivate: [AuthorizationGuardService],
  },
  {
    path: AppConstants.routePaths.markets,
    component: MarketsComponent,
    canActivate: [AuthorizationGuardService],
  },
  {
    path: AppConstants.routePaths.demographics,
    loadChildren: () =>
      import('../app/pages/demographics/demographics.module').then(
        (t) => t.DemographicsModule
      ),
    canActivate: [AuthorizationGuardService, ActionGuard],
    data: {
      entityArea: EntityArea.Demographic,
    },
  },
  {
    path: AppConstants.routePaths.profile,
    component: ProfileComponent,
    canActivate: [AuthorizationGuardService],
  },
  {
    path: AppConstants.routePaths.home,
    component: HomeComponent,
    canActivate: [AuthorizationGuardService],
  },
  {
    path: AppConstants.routePaths.login,
    component: LoginFormComponent,
  },
  {
    path: AppConstants.routePaths.unknown,
    redirectTo: AppConstants.routePaths.home,
    canActivate: [AuthorizationGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule, TranslateModule],
  declarations: [LogoutComponent],
})
export class AppRoutingModule {}
