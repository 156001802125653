import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app-constants';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  logo: string = `${environment.rcsCdnUrl}/aquiraplannerau/images/AquiraPlanner_200x53-w_working.png`;
  loginUrl: string = `${environment.securityApiUrl}/authentication/login`;
  isAuthenticated: boolean;
  loginModel: { username: string; returnUrl: string } = {
    username: '',
    returnUrl: environment.redirectUri,
  };
  isFormValid: boolean = false;
  emailInputOptions = {
    onValueChanged: (e: any) => {
      this.isFormValid = e.component.option('isValid');
      this.loginModel.username = e.value;
    },
  };

  constructor(
    private auth: AuthorizationService,
    private router: Router,
    public globalService: AppConstantService
  ) {}

  async ngOnInit() {
    this.isAuthenticated = this.auth.isAuthenticated();
    console.log('Checking user authentication');
    if (this.isAuthenticated) {
      console.log('LoginForm: User is Authenticated, route to home');
      this.router.navigate([`/${AppConstants.routePaths.home}`]);
    }
  }

  async signIn(e) {
    e.preventDefault();
    if (this.isFormValid) {
      let loginForm = document.forms[0];
      loginForm.action = this.loginUrl;
      loginForm.method = 'get';
      loginForm.submit();
    }
  }
}
