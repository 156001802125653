import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    editingTexts: any = {
        addRow: marker("Add a row"),
        cancelRowChanges: marker("Cancel"),
        confirmDeleteMessage: marker("Are you sure you want to delete this record?"),
        deleteRow: marker("Delete"),
        editRow: marker("Edit"),
        saveRowChanges: marker("Save")
    };

    groupingTexts: any = {
        groupByThisColumn: marker('Group by This Column'),
        ungroupAll: marker('Ungroup All')
    };

    groupOperationDescriptions: any = {
        and: marker("And"),
        or: marker("Or")
    };

    operationDescriptions: any = {
        between: marker("Between"),
        contains: marker("Contains"),
        endsWith: marker("Ends With"),
        equal: marker("Equals"),
        greaterThan: marker("Greater Than"),
        greaterThanOrEqual: marker("Greater than or equal to"),
        lessThan: marker("Less than"),
        lessThanOrEqual: marker("Less than or equal to"),
        notContains: marker("Does not contain"),
        notEqual: marker("Does not equal"),
        startsWith: marker("Starts with")
    };

    constructor(private translate: TranslateService){}

    async getEditingTexts() {
        return this.translateObjects(this.editingTexts);
    }

    async getGroupingTexts(){
        return this.translateObjects(this.groupingTexts);
    }

    async getGroupOperationDescriptions() {
        return this.translateObjects(this.groupOperationDescriptions);
    }

    async getOperationDescriptions() {
        return this.translateObjects(this.operationDescriptions);
    }

    translateSingle(key: string): string{
        let result = key;
        this.translate.get(key).subscribe((value)=>{
            result = value;
        });
        return result;
    }

    async translateObjects(raw: any) {
        let observables: Observable<string>[] = [];
        for (let key in raw) {
            observables = {...observables, ...{[key]: this.translate.get(raw[key])}};
        }
        return forkJoin(observables).toPromise();
    }
}