import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookUpComponent } from './components/look-up/look-up.component';
import { MultiSelectDropDownComponent } from './components/multi-select-drop-down/multi-select-drop-down.component';
import { FormLabelComponent } from './components/form-label/form-label.component';
import { DxButtonModule, DxCheckBoxModule, DxDataGridModule, DxDropDownBoxModule, DxFormModule, DxLoadIndicatorModule, DxLoadPanelModule, DxLookupModule, DxScrollViewModule, DxSelectBoxModule, DxTooltipComponent, DxTooltipModule, DxTreeListModule, DxTreeViewModule, DxValidatorModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { MultiSelectTreeViewDropDownComponent } from './components/multi-select-tree-view-drop-down/multi-select-tree-view-drop-down.component';
import { IndicatorButtonComponent } from './components/indicator-button/indicator-button.component';
import { TranslateModule } from '@ngx-translate/core';
import dxScrollView from 'devextreme/ui/scroll_view';
import { DxiValidationRuleComponent, DxiValidationRuleModule, DxoValidationModule } from 'devextreme-angular/ui/nested';
import { GridviewComponent } from './components/gridview/gridview.component';
import { ContainerComponent } from './components/content-container/container.component';
import { TitleComponent } from './components/content-container/title/title.component';
import { ContentComponent } from './components/content-container/content/content.component';
import { DemographicsSingleSelectLookUpComponent } from '../pages/calculations/commen/demographics-single-select-look-up/demographics-single-select-look-up.component';
import { DemographicsMultiSelectLookupComponent } from '../pages/calculations/commen/demographics-multi-select-lookup/demographics-multi-select-lookup.component';
import { AudioTypeLookUpComponent } from '../pages/calculations/commen/audio-type-look-up/audio-type-look-up.component';
import { PlaceLookUpComponent } from '../pages/calculations/commen/place-look-up/place-look-up.component';

@NgModule({
  declarations: [
    LookUpComponent,
    MultiSelectDropDownComponent,
    FormLabelComponent,
    MultiSelectTreeViewDropDownComponent,
    IndicatorButtonComponent,
    GridviewComponent,
    ContainerComponent,
    TitleComponent,
    ContentComponent,
    DemographicsSingleSelectLookUpComponent,
    DemographicsMultiSelectLookupComponent,
    AudioTypeLookUpComponent,
    PlaceLookUpComponent
  ],
  imports: [
    CommonModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxDropDownBoxModule,
    //DxTreeViewModule,
    DxValidatorModule,
    DxFormModule,
    DxLookupModule,
    DxLoadPanelModule,
    DxLoadIndicatorModule,
    DxTreeListModule,
    DxScrollViewModule,
    FormsModule,
    DxDataGridModule,
    TranslateModule,
    DxTooltipModule
  ],

  exports: [
    //exported components
    LookUpComponent,
    MultiSelectDropDownComponent,
    MultiSelectTreeViewDropDownComponent,
    FormLabelComponent,
    ContentComponent,
    TitleComponent,
    ContainerComponent,
    GridviewComponent,
    DemographicsSingleSelectLookUpComponent,
    DemographicsMultiSelectLookupComponent,
    AudioTypeLookUpComponent,
    PlaceLookUpComponent,
    //exported modules
    FormsModule,
    DxFormModule,
    DxDataGridModule,
    TranslateModule
  ],
})
export class SharedModule { }