import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NavigationAction } from 'src/app/core/enums/navigation-action.enum';
import { AllowedActionsModel } from 'src/app/core/models/allowed-actions-model';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { ApplicationArea } from '../enums/application-area.enum';
import { ApplicationSecurity } from '../applicationSecurity/application-security';
import { UserModel } from '../models/user/user-model';
import { UserSession } from '../models/user/user-session';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderNotificationModel } from '../models/ui/loader-notification-model';
import { AppConstants } from 'src/app/app-constants';

@Injectable({
  providedIn: 'root',
})
export class AppConstantService {
  constructor(public auth: AuthorizationService) {}

  apiURL = environment.apiUrl;
  securityApiUrl = environment.securityApiUrl;
  urlSeparator = '/';
  applicationSecurity: ApplicationSecurity;
  static userSessionName = 'user';

  defaultTheme = {
    id: 'generic.light.compact',
    name: 'Generic Light Compact',
  };

  localStorageThemeKeyName = 'AquiraCloudTheme';

  private _loaderNotification$ = new BehaviorSubject<LoaderNotificationModel>(
    new LoaderNotificationModel('Loading', false)
  );

  get loaderNotification$() {
    return this._loaderNotification$;
  }

  apiPath = {
    applicationSettings: 'application-settings',
    audioTypes: 'audiotypes',
    calculators: {
      controllerName: 'calculators',
      actions: {
        impacts: 'impacts',
        randFBySchedule: 'reach-and-frequency-by-schedule',
        randFBySpotList: 'reach-and-frequency-by-spotlist',
        action_SurveyCheck: 'survey-check',
        surveyComparison: 'survey-comparison',
      },
    },
    companies: 'companies',
    demographicLookups: 'DemographicLookups',
    demographics: 'demographics',
    locations: 'locations',
    login: 'login',
    markets: 'Markets',
    password: {
      controllerName: 'password',
      actions: {
        reset: 'reset',
        modify: 'modify',
      },
    },
    places: 'places',
    platforms: 'platforms',
    surveys: 'surveys',
    users: 'users',
    userRoles: 'user-roles',
    values: 'values',
    variables: 'variables',
  };

  getAbsUrl(path: string) {
    const absPath = location.href;
    return absPath.split(environment.baseHref)[1] + this.urlSeparator + path;
  }

  getDetailUrl(area: ApplicationArea, id, navAction: NavigationAction) {
    return [area.toString() + '/detail', id, navAction];
  }

  showLoadingNotification(showLoading: boolean, message?: string) {
    let loaderNotifcationModel = new LoaderNotificationModel(
      message,
      showLoading
    );
    this.loaderNotification$.next(loaderNotifcationModel);
  }

  /**Not used */
  //   isActionAllowed(navAction: string, allowedAction: AllowedActionsModel) {
  //     const action: NavigationAction = navAction as NavigationAction;
  //     switch (action) {
  //       case NavigationAction.Create :
  //       return allowedAction.hasAdd;

  //       case NavigationAction.Edit :
  //       return allowedAction.hasModify;

  //       case NavigationAction.Delete :
  //       return allowedAction.hasDelete;

  //       case NavigationAction.View :
  //       return allowedAction.hasGet;
  //     }
  //   }
}
