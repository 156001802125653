import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { AudioTypeModel } from 'src/app/core/models/audio-type-model';
import { DropDownModel } from 'src/app/core/models/ui/drop-down-model';
import { TreeViewModel } from 'src/app/core/models/ui/tree-view-model';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { AudioTypesService } from 'src/app/core/services/audioType/audio-types.service';

@Component({
  selector: 'app-audio-type-look-up',
  templateUrl: './audio-type-look-up.component.html',
  styleUrls: ['./audio-type-look-up.component.scss']
})
export class AudioTypeLookUpComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject<boolean>();
  audioTypeLookUp = new Array<DropDownModel>();
  @Input() selectedAudioTypes: TreeViewModel[];
  @Output() selectedAudioTypesChange = new EventEmitter<TreeViewModel[]>();

  constructor(private audioTypesService: AudioTypesService, private appService: AppConstantService) { }

  ngOnInit() {
    this.loadAudioTypes();
  }

  loadAudioTypes() {
    this.audioTypesService.get<ApiResponse<AudioTypeModel[]>>(this.appService.apiPath.audioTypes)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(s => {
        this.onAudioTypesGetSucess(s.data.entity)
      })
  }

  onAudioTypesGetSucess(audioTypeModel: AudioTypeModel[]) {
    audioTypeModel = audioTypeModel.filter(at => at.audioTypeId !== 0);

    this.audioTypeLookUp = audioTypeModel.map(m => ({id: m.audioTypeId, name: m.audioTypeDesc, isChecked: false}));
  }

  audioTypeChanged(eventArgs) {
    this.selectedAudioTypes = eventArgs;
    this.selectedAudioTypesChange.emit(this.selectedAudioTypes);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
  }
}
