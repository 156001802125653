<app-container title="{{ 'Markets' | translate }}">

  <app-content>
    <dx-data-grid
    [dataSource]="dataSource"
    [showBorders]="true"
    [filterRow]="{showAllText: '(All)'|translate}"
    [focusedRowEnabled]="true"
    [focusedRowIndex]="0"
    [columnAutoWidth]="true"
    [columnHidingEnabled]="false"
    keyExpr="marketId"
    noDataText="{{'No data' | translate}}"
    [rowAlternationEnabled]="true"
    [showColumnLines]="true"
    [loadPanel]="{enabled:true, text: 'Loading...'|translate}">
  
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager infoText="{{'Page {0} of {1} ({2} items)'| translate}}" [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
    <dxo-filter-row betweenEndText="{{'End'|translate}}" betweenStartText="{{'Start' | translate}}" [operationDescriptions]="operationDescriptions" resetOperationText="{{'Reset' | translate}}" [visible]="true"></dxo-filter-row>
    <dxo-sorting ascendingText="{{'Sort Ascending' | translate}}" clearText="{{'Clear Sorting'|translate}}" descendingText="{{'Sort Descending'|translate}}"></dxo-sorting>
  
    <dxi-column
      dataField="marketId"
      caption="{{'Market ID' | translate}}"
      alignment="left"
      sortOrder="asc">
    </dxi-column>
    <dxi-column
      dataField="market2"
      caption="{{'Market2' | translate}}">
    </dxi-column>
    <dxi-column
      dataField="shortDescription"
      caption="{{'Short Description' | translate}}">
    </dxi-column>
    <dxi-column
      dataField="longDescription"
      caption="{{'Long Description' | translate}}">
    </dxi-column>
    <dxi-column
      dataField="marketInHundreds"
      caption="{{'Market In Hundreds' | translate}}"
      falseText="{{'false' | translate}}"
      trueText="{{'true' | translate}}"
      width="170px">
    </dxi-column>
  </dx-data-grid>
  </app-content>
</app-container>