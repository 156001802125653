import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationModel } from 'src/app/core/models/notification-model';
import { NotifyType } from '../../../../enums/notify-type.enum';


@Component({
  selector: 'app-notify-template',
  templateUrl: './notify-template.component.html',
  styleUrls: ['./notify-template.component.scss']
})
export class NotifyTemplateComponent implements OnInit, OnChanges {

 @Input() templateIcon: string;
 @Input() notifyModel: NotificationModel;
 @Input() templateType: string;
 @Input() tempClass: string;
 @Output() notifyModelChange = new EventEmitter<NotificationModel>();
 theme = 'dx-toast-success';

  constructor() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    let templateType = changes?.templateType?.currentValue;

    if (templateType) {
      this.templateIcon = this.setNotificationIcon(templateType)
    }
  }

  ngOnInit() {
  } 



  toaster_onClose($event) {
    this.notifyModel.notify = false;
    this.notifyModelChange.emit(this.notifyModel);
  }

  setNotificationIcon = (notifyType: NotifyType) => {  
    let headerIcon = ''
    switch(notifyType) {
      case NotifyType.Success :
        headerIcon = 'fas fa-check-circle'
        break;
      case NotifyType.Error :
        headerIcon = 'far fa-times-circle'
        break;
      case NotifyType.Info :
        headerIcon = 'fas fa-exclamation-circle'
        break;
      case NotifyType.Warning :
        headerIcon = 'fas fa-exclamation-triangle'
        break; 
    }
    return headerIcon;    
  }


}
