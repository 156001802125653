import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { LookupModel } from 'src/app/core/models/lookup-model';
import { DropDownModel } from 'src/app/core/models/ui/drop-down-model';
import CustomStore from 'devextreme/data/custom_store';


@Component({
  selector: 'app-look-up',
  templateUrl: './look-up.component.html',
  styleUrls: ['./look-up.component.scss']
})
export class LookUpComponent implements OnInit, OnChanges {

  @Input()
  dataSource: LookupModel[];

  @Input()
  value: LookupModel;

  @Input()
  requiredValidationMessage = 'required';

  @Input()
  enableRequiredValidation = true;

  @Input()
  width;

  @Input()
  enableSearch = false;

  @Input()
  cssClass = 'form-control';

  @Output()
  selectionChange = new EventEmitter<LookupModel>();

  dxDataSource: DataSource;

  constructor() { }

  ngOnInit() {
    this.setDataSource(this.dataSource);
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes.dataSource?.currentValue;
    if (change) {
      this.dataSource = change;
      this.setDataSource(change);
    }
  }

  onSelectionChanged(eventArgs) {
    let selectedLookupItem: LookupModel = eventArgs.selectedItem;
    this.selectionChange.emit(selectedLookupItem);
  }

  setDataSource(data) {
    this.dxDataSource = new DataSource({
       store: data,
       paginate: true,
       pageSize: 10
    })
  }

}
