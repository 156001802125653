import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DxDataGridModule,
        DxFormModule,
        DxButtonModule,
        DxListModule,
        DxCheckBoxModule,
        DxTemplateModule,
        DxTabPanelModule,
        DxValidatorModule,
        DxTextBoxModule ,
        DxDropDownBoxModule,
        DxTagBoxModule,
        DxFilterBuilderModule,
        DxPopupModule,
        DxNumberBoxModule,
        DxTreeListModule
        } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { DemographicFilterBuilderComponent } from './demographic-filter-builder/demographic-filter-builder.component';
import { DemographicsComponent } from './demographics.component';
import { DemographicsRoutingModule } from './demographics-routing.module';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    DxTabPanelModule,
    DxDataGridModule,
    DxFormModule,
    DxCheckBoxModule,
    DxTemplateModule,
    DemographicsRoutingModule,
    DxValidatorModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxFilterBuilderModule,
    DxTagBoxModule,
    DxButtonModule,
    DxPopupModule,
    DxNumberBoxModule,
    DxListModule,
    DxTreeListModule,
    FormsModule,
    SharedModule
  ],
  declarations: [
    DemographicsComponent,
    DemographicFilterBuilderComponent

  ]
})
export class DemographicsModule { }