import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { NotifyType } from 'src/app/core/enums/notify-type.enum';
import { HttpResult } from 'src/app/core/helpers/http-result';
import { SurveyLookupModel } from 'src/app/core/models/survey-lookup-model';
import { SurveyModel } from 'src/app/core/models/survey-model';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { NotifyService } from 'src/app/core/services/notify.service';
import { SurveysService } from 'src/app/core/services/survey/surveys.service';
import 'devextreme/integration/jquery';
import { TranslationService } from 'src/app/shared/services';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { CheckActions } from 'src/app/core/interface/check-actions';
import { EntityArea } from 'src/app/core/enums/entity-area.enum';
import { EntityAction } from 'src/app/core/enums/entity-action.enum';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit, CheckActions {
  @ViewChild(DxDataGridComponent, { static: false }) surveyGrid: DxDataGridComponent
  cache: string[] = [];
  headers = { 'Authorization': `Bearer ${this.auth.userSession.tokens.AccessToken}` };
  surveys: SurveyLookupModel[];
  survey: SurveyModel;
  value: any[] = [];
  apiUrl = `${this.appService.apiURL}/Surveys`;
  editingTexts: any;
  focusedRowKey: string;
  operationDescriptions: any;
  entityArea : EntityArea = EntityArea.Survey;

  constructor(private appService: AppConstantService,
              private auth: AuthorizationService,
              private notifyService: NotifyService,
              private surveyService: SurveysService,
              private translate: TranslateService,
              private translation: TranslationService,
              private authService: AuthorizationService
              ) {

          this.checkDeleteAccess = this.checkDeleteAccess.bind(this);
          this.checkUpdateAccess = this.checkUpdateAccess.bind(this);
  }
 
  checkUpdateAccess(e): boolean {
    /* Check the Get access so users can see all the information including stations and program events, because the Update is disabled for all users. */
    return this.authService.hasAccess(EntityArea.Survey,EntityAction.Read);
  }
  
  checkDeleteAccess(e): boolean {
    return this.authService.hasAccess(EntityArea.Survey,EntityAction.Delete);
  }

  getSurveys(uploaded: boolean){
    let previousSurveys = this.cache;
    this.surveyService.getSurveyLookup().subscribe( s => {
      this.surveys = s.data.entity;
      this.cache = this.surveys.map((survey)=>survey.surveyCode);
      if(uploaded){
        let difference = this.cache.filter(x => !previousSurveys.includes(x));
        this.focusedRowKey = difference ? difference[0] : this.focusedRowKey;
        console.log("Cache: " + this.cache + " Previous: " + previousSurveys + " Focused Row Key: " + this.focusedRowKey);
      } else if(this.cache.length){
        this.focusedRowKey = this.cache[0];
      }
    });
  }

  async ngOnInit() {
    this.editingTexts = await this.translation.getEditingTexts();
    this.operationDescriptions = await this.translation.getOperationDescriptions();
    this.translate.get(marker('View')).subscribe((result)=>{
      this.editingTexts.editRow = result;
    })
    this.getSurveys(false);
  }

  onDataGridContentReady(e){
    e.component.columnOption("command:edit", "visibleIndex", -1);
  }

  onEditingStart(e){
    this.surveyService.getSurveyByCode(e.key).subscribe( s => {
      this.survey = s.data.entity;
    });
  }

  onRowRemoving(e:any): void{
    this.surveyService.deleteByID(this.appService.apiPath.surveys, e.data.surveyCode).subscribe(
      (resp) => {
        this.notifyService.notifySuccess(e.data.description + ' ' + this.translation.translateSingle(marker("deleted")), this.translation.translateSingle("Success"));
        this.cache = this.cache.filter((survey)=>survey !== e.data.surveyCode);
      },
      (err: HttpResult<SurveyModel>) => {
        this.notifyService.notifyError(err, err.statusText);
      }
    );
  }

  onSelectionChanged () {
    this.surveyGrid.instance.updateDimensions();
  }

  onFileUploaderContentReady(e) {
    e.element.find(".dx-fileuploader-button").dxButton("instance").option("icon", "upload");
    e.element.find(".dx-fileuploader-button").dxButton("instance").option("hint", this.translation.translateSingle(marker("Import")));
  }

  onUploaded(e){
    this.getSurveys(true);
  }

  onUploadError(e){
    this.notifyService.showNotification(e.error.response, NotifyType.Error, e.error.status);
  }
  
}