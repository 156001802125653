import { Component, OnInit } from '@angular/core';
import { VariableService } from 'src/app/core/services/variable/variables.service';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.scss']
})
export class VariablesComponent implements OnInit {
  dataSource: any;
  operationDescriptions: any;

  constructor(private variableService: VariableService, private translationService: TranslationService) { 
  }

  async ngOnInit() {
    this.variableService.getVariables().subscribe((res:any)=>{
      this.dataSource = res.data.entity
    });

    this.operationDescriptions = await this.translationService.getOperationDescriptions();
  }

}