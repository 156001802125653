import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DropDownModel } from 'src/app/core/models/ui/drop-down-model';

@Component({
  selector: 'app-multi-select-drop-down',
  templateUrl: './multi-select-drop-down.component.html',
  styleUrls: ['./multi-select-drop-down.component.scss']
})
export class MultiSelectDropDownComponent implements OnInit, OnChanges {

  @Input()
  canShow = false;

  @Input()
  disabled = false;

  @Input()
  dropDownValues = new Array<DropDownModel>();

  @Input()
  rangeSelection = false;

  @Input()
  enableSearch = false;

  @Input()
  enableRequiredValidation = true;

  @Input()
  requiredValidationMessage = 'required';

  @Output()
  saveChanges = new EventEmitter();

  selectedValues = undefined;

  filterValues  = new Array<DropDownModel>();


  chkSelectAll = false;
  betweenMinVal: number;
  betweenMaxVal: number;

  constructor() { }

  ngOnInit() {
    this.chkSelectAll = false;
  }

  ngOnChanges(changes: SimpleChanges) {

    const changedValue = changes.dropDownValues.currentValue;

    if (changedValue) {
      this.dropDownValues = changedValue;
      this.filterValues = changedValue;
      this.chkSelectAll = false;
      this.selectedValues = undefined;
    }
  }

  onInitialised(eventArgs: any) {
    this.betweenMinVal = undefined;
    this.betweenMaxVal = undefined;
    this.filterValues = this.dropDownValues;
  }

    // when the select all checkbox is checked or unchecked
    chkSelectAll_checked(isChecked: boolean) {
      if (isChecked) {
      this.filterValues.forEach(v => {
        v.isChecked = true;
      });
      this.chkSelectAll = true;
    } else
      if (!isChecked) {
      this.filterValues.forEach(v => {
        v.isChecked = false;
      });
      this.chkSelectAll = false;
    }
  }

       // when user selects / unselects a single value
  chkValueSelect_checked(isChecked: boolean, selectedValue: DropDownModel) {
    if (isChecked) {
      this.filterValues.find(f => f.id === selectedValue.id).isChecked = true;

    } else
    if (!isChecked) {
      const fdval = this.filterValues.find(f => f.id === selectedValue.id).isChecked = false;
      this.chkSelectAll = false;
    }
  }

  onSearchTextChange(eventArgs: any) {
    let srcElement = eventArgs.srcElement.value as string;
    srcElement = srcElement.toLowerCase();
    if (srcElement && srcElement.length > 0) {
      this.filterValues = this.dropDownValues.filter(f => f.name.toLowerCase().includes(srcElement));
      if ((this.filterValues.length === this.filterValues.filter(f => f.isChecked === true).length)
                            && (this.filterValues.length > 0)) {
                              this.chkSelectAll = true;
                            } else {
                              this.chkSelectAll = false;
                            }
    } else {
      this.filterValues = this.dropDownValues;
      this.chkSelectAll = this.filterValues.filter(f => f.isChecked).length === this.dropDownValues.length;
    }
    console.log(srcElement);
  }

    // once the user presses the apply button
    saveFilterValuesChanges(eventArgs: any) {

      if (((this.betweenMinVal || this.betweenMinVal === 0) ||
          (this.betweenMaxVal || this.betweenMaxVal === 0) )) {

        // if the start range is not specified
        if (!this.betweenMinVal) {
          this.betweenMinVal = Number(this.filterValues[0].name);
        }
        // if end range is not specified
        if (!this.betweenMaxVal) {
          this.betweenMaxVal = Number(this.filterValues[this.filterValues.length - 1].name);
        }
        // adding values between the range specified
        this.filterValues
            .forEach( v => {
              if (!isNaN(Number(v.name)) &&  Number(v.name) >= this.betweenMinVal && Number(v.name)  <= this.betweenMaxVal) {
                v.isChecked = true;
              }
            });
      }
      const selValues = this.filterValues.filter(f => f.isChecked === true);
      this.saveChanges.emit(selValues);
      this.canShow = false;
     // this.chkSelectAll = false;

      if (selValues && selValues.length > 0) {
        this.selectedValues = selValues.map(m => m.name).join(',  ');
      } else {
        this.selectedValues = undefined;
      }
    }

}

export class DropDownValue {
  id: number;
  name: string;
  isChecked: boolean;
  disabled?: boolean;
}