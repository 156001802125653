<dx-select-box
    [dataSource]="dxDataSource"
    [displayExpr]="'name'"
    [displayValue]="'id'"
    [valueExpr]="'id'"
    [class]="cssClass"
    [dropDownOptions]="{maxHeight: '90vh'}"
    [searchEnabled]="enableSearch"
    [searchExpr]="['name']"
    [searchMode]="'contains'"
    [width]="width"   
    [value]="value"
    [deferRendering]="false"
    (onSelectionChanged)="onSelectionChanged($event)"
    placeholder="{{'Select an item'|translate}}">
    <dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false" >
    </dxo-scrolling>
    <dx-validator>
        <dxi-validation-rule *ngIf="enableRequiredValidation" [type]="'required'" [message]="requiredValidationMessage" ></dxi-validation-rule>
    </dx-validator>
</dx-select-box>

