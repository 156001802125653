import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})

export class HttpInterceptorService implements HttpInterceptor {

  constructor(private auth : AuthorizationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authHeader = `Bearer ${this.auth.userSession.tokens.AccessToken}`;
        const httpRequest = req.clone({ setHeaders: {'Authorization': authHeader} });
        return next.handle(httpRequest)
        .pipe(
           tap(events => {
            this.handleResponse(events);
            })
        );
  }

  handleResponse(events: HttpEvent<any>) {
    /**console.log('HttpInterceptorService: status is ', events);**/
  }

}
