import { DropDownModel } from './drop-down-model';

export class TreeViewModel extends DropDownModel {
  parentId?: number | string = -1;
  children?: TreeViewModel[];
  hasItems? = false;

   constructor(id?: number | string, name?: string, isChecked?: boolean, parentId?: number|string, children?: TreeViewModel[]) {
     super(id, name, isChecked);
     this.parentId = parentId;
     this.children = children;
   }

}


