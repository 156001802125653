import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import { ValueModel } from 'src/app/core/models/value/value-model';
import { AppConstants } from 'src/app/app-constants';
import { DropDownValue } from 'src/app/shared/components/multi-select-drop-down/multi-select-drop-down.component';
import { DxFilterBuilderComponent } from 'devextreme-angular';
import { NotifyService } from '../../../core/services/notify.service';
import { VariableModel } from 'src/app/core/models/variable-model';
import { DropDownModel } from 'src/app/core/models/ui/drop-down-model';

@Component({
  selector: 'app-demographic-filter-builder',
  templateUrl: './demographic-filter-builder.component.html',
  styleUrls: ['./demographic-filter-builder.component.scss']
})
export class DemographicFilterBuilderComponent implements OnInit, OnChanges {

  @Input() filterFields: VariableModel[];
  @Input() fieldValues: any[];
  @Input() isViewOnly: boolean;
  @Input() filterValues: any;
  @Input() selectedFilterFieldsLookup = new Array<DropDownValue>();
  @Output() filterValuesChange: any = new EventEmitter<any>();
  demographicVariablesLookup = new Array<DropDownModel>();
 @ViewChild('dxFilter') dxFilter: DxFilterBuilderComponent;

  filterDropDownValues: ValueModelCheckBox[];      // holds the values for showing for currently selected filter field
  userSelectedfilterValues: ValueModelCheckBox[];   // holds the user selected values for the currently selected variable
  showFilterValuesDropDown = false;                 // show / hide the filter drop down
  filterModel: any[];

  betweenMinVal: number;
  betweenMaxVal: number;
  checkSelectAllValues = false;
  ageVariableType = false;
  filterBuilderFields = new Array();//Comment out for now, for some reason, variable model in UK planner is empty. <VariableModel>(); // lookup in the filter builder
  disableFilterBuilder = true; // determines weather to enable/ disable filter builder


filterOperationDescriptions = {
  contains: 'In List',
  between: 'between'
  };

  // specifying custom operation for the filter builder
  anyOfOperation = [{
    name: 'contains',
    caption: 'In List',
    editorTemplate: 'betweenTemplate',
}];

  constructor(private notifyService: NotifyService) {
               }

  ngOnInit() {
    this.disableFilterBuilder = this.isViewOnly;
    this.userSelectedfilterValues = new Array<ValueModel>();
    this.filterModel = this.filterValues;
    this.demographicVariablesLookup = this.getVariableLookup();
    if (this.selectedFilterFieldsLookup.length > 0 ) {
      this.filterBuilderFields = this.selectedFilterFieldsLookup;
    } else {
      this.filterBuilderFields = this.demographicVariablesLookup
                                     .filter(f => f.isChecked);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedFilterFieldsLookup && changes.selectedFilterFieldsLookup.currentValue) {
    this.demographicLookupApplyChanges(changes.selectedFilterFieldsLookup.currentValue);
    }
    this.disableFilterBuilder = this.isViewOnly;
  }

  /*
    called when the filter builder fields dropdown is opened.
  */
  onDemographicLookupOpened (eventArgs: any) {
    if (this.dxFilter && this.dxFilter.instance) {
      this.filterValues = this.dxFilter.instance['_model'];
      this.filterValuesChange.emit(this.filterValues);
    }
  }

  /*
  updates the filter builder fields dropdown based on the values from the filter builder
  */
  demographicLookupApplyChanges(filterFieldChanges: VariableModel[]) {
    if (filterFieldChanges) {
      this.demographicVariablesLookup.forEach(v => {
         //v.disabled = false;
         v.isChecked = this.filterBuilderFields.some(f => f.id === v.id);
        } );
      filterFieldChanges.forEach(f => {
        const lookupModel = this.demographicVariablesLookup.find(s => s.id === f.variableId);
        if (lookupModel) {
        //lookupModel.disabled = lookupModel.isChecked = true;
        }

        const hasInField = this.filterBuilderFields.find(fb => fb.id === f.variableId);
        if (!hasInField) {
          this.filterBuilderFields.push(f as VariableModel);
        }
      });
    }
  }

  /*
   Initialize the filter builder drop down
  */
  getVariableLookup() {
    const checkedState = this.selectedFilterFieldsLookup.length ? false : true;
    const demoVariableLookup: DropDownModel[] = this.filterFields as DropDownModel[];
    demoVariableLookup.forEach(f => {
      f.isChecked = checkedState;
      //f.disabled = false;
    });
    this.selectedFilterFieldsLookup.forEach(val => {
     //const dropDownModel: DropDownValue = demoVariableLookup.find(f => f.id === val.id);
     //dropDownModel.disabled = dropDownModel.isChecked = true;
    });
    return demoVariableLookup;
  }

  /*
   Update the selected demographic variables from the variables dropdown to the filter builder
  */
  onApplyChanges(eventArgs) {
     const isValid = this.demographicVariablesLookup.some(s => s.isChecked);
     this.disableFilterBuilder = !isValid;
    if (!isValid) {
      this.filterBuilderFields = new Array<VariableModel>();
      //this.notifyService.notifyWarning('Demographic variable required', AppConstants.errorMessage.validationError, 2000);
      return;
    }

    this.demographicVariablesLookup.forEach((f, index ) => {
      const hasAny = this.filterBuilderFields.some(s => s.id === f.id);
      const isUsed = this.selectedFilterFieldsLookup.some(s => s.id === f.id);

      if (hasAny && !isUsed && !f.isChecked) {
        const indexToDelete = this.filterBuilderFields.findIndex(i => i.id === f.id);
        this.filterBuilderFields.splice(indexToDelete, 1);
      } else if (!hasAny && f.isChecked && !isUsed) {
        this.filterBuilderFields.push(f);
      }
    });

  }

  // when the fillter values drop down is initalized
  onInitialised(eventArgs) {
    const variableId = eventArgs.field.editorOptions.data.id;
    this.showFilterValuesDropDown = true;
    this.ageVariableType = this.isAgeVariableType(variableId);
    this.filterDropDownValues = this.fieldValues.filter(f => f.variableId === variableId);
    let selVal: any[];
    this.userSelectedfilterValues = [];

    if (eventArgs.value) {
      selVal = eventArgs.value;
      this.checkSelectAllValues = this.filterDropDownValues.length === selVal.length;
      this.filterDropDownValues.forEach(v => {
        v.isChecked = selVal.some(s => s === v.name);
        if (v.isChecked) {
          this.userSelectedfilterValues.push(v);
        }
       });
    } else {
      this.filterDropDownValues.forEach(v => {
        v.isChecked = false;
      });
      this.checkSelectAllValues = false;
    }
    this.betweenMinVal = undefined;
    this.betweenMaxVal = undefined;
  }


  // when the select all checkbox is checked or unchecked
  demoCheckSelectAll_checked(isChecked: boolean) {

    const allValues = document.getElementsByClassName('demographicFilterValue');
    /*
      when we select values from the variables drop down it updates the filter builder lookup. After that for some reason
      the angular does not detect the model changes in the values lookup. so we manualy add the
      dx-checkbox-checked class to all the checkboxes. I coudn't find any other way to solve this issue.
    */
    if (isChecked) {
      for (let i = 0; i < allValues.length; i++) {
        allValues.item(i).classList.add('dx-checkbox-checked');
        console.log(allValues.item(i).getAttribute('ng-reflect-element-attr'));
      }
    } else if (!isChecked) {
      for (let i = 0; i < allValues.length; i++) {
        allValues.item(i).classList.remove('dx-checkbox-checked');
      }
    }

    this.filterDropDownValues.forEach(v => {
      v.isChecked = isChecked;
      if (!this.userSelectedfilterValues.some(s => s.id === v.id)) {
         this.userSelectedfilterValues.push(v);
      }
    });

    if (!isChecked) {
    this.userSelectedfilterValues = [];
    this.checkSelectAllValues = isChecked;
    }
   }

   // once the user presses the apply fitler button
   saveFilterValuesChanges(eventArgs) {
    this.filterDropDownValues.forEach(f => f.isChecked = true);
    const variableId = eventArgs.field.editorOptions.data.id;
    let selVal = new Array<ValueModel>();

    if (((this.betweenMinVal || this.betweenMinVal === 0) ||
        (this.betweenMaxVal || this.betweenMaxVal === 0) )) {

      // if the start range is not specified
      if (!this.betweenMinVal) {
        this.betweenMinVal = Number(this.filterDropDownValues[0].name);
      }
      // if end range is not specified
      if (!this.betweenMaxVal) {
        this.betweenMaxVal = Number(this.filterDropDownValues[this.filterDropDownValues.length - 1].name);
      }
      // adding values between the range specified
      this.filterDropDownValues.filter(f => f.variableId === variableId)
          .forEach( v => {
            if (!isNaN(Number(v.name)) &&  Number(v.name) >= this.betweenMinVal && Number(v.name)  <= this.betweenMaxVal) {
              if (!this.userSelectedfilterValues.find(f => f.id === v.id)) {
              selVal.push(v);
              }
            }
          });

      // grabing any used selected values
      this.userSelectedfilterValues.filter(f => f.variableId === variableId)
      .forEach( v => {
        if (v.name as any >= this.betweenMinVal && v.name as any <= this.betweenMaxVal) {
          selVal.push(v);
        }
      });

      // remove duplicate values if any
      selVal = selVal.filter((v, i, self) => {
        return self.indexOf(v) === i;
      });

      // sorting and saving to the filter builder control
      eventArgs.setValue(selVal.sort((a, b) => (a.name as any) - (b.name as any)).map(m => m.name));
     // this.userSelectedfilterValues = [];
    } else
    // saving user selected values
      if (this.userSelectedfilterValues) {
      selVal = this.userSelectedfilterValues;
      eventArgs.setValue(selVal.map(m => m.name));
      }
    this.checkSelectAllValues = false;
    eventArgs.closeEditor();
  }

  // when user selects / unselects the value
  demoChkValueSelect_checked(isChecked: boolean, selectedValue: ValueModel) {
    if (isChecked) {
      const selVal = this.fieldValues.find(f => f.id === selectedValue.id);
      if (!this.userSelectedfilterValues.some(s => s.id === selVal.id)) {
           this.userSelectedfilterValues.push(selVal);
      }
      const fdval = this.filterDropDownValues.find(f => f.id === selectedValue.id);
      if (fdval) {
        fdval.isChecked = true;
      }
    } else
    if (!isChecked) {
      const indexToRemove = this.userSelectedfilterValues.findIndex( v => v.id === selectedValue.id);
      if (indexToRemove) {
      this.userSelectedfilterValues.splice(indexToRemove, 1);
      }
      const fdval = this.filterDropDownValues.find(f => f.id === selectedValue.id);
      if (fdval) {
        fdval.isChecked = false;
      }
      this.checkSelectAllValues = false;
    }
    this.checkSelectAllValues = this.filterDropDownValues.every(f => f.isChecked === true);

  }


  isAgeVariableType(variableId: number) {
    const ageVariable = AppConstants.variableFilterOperator.find(f => f.id === variableId);
    if (ageVariable) {
      return true;
    }
    return false;
  }

  // whenever a value is applied to the filter row these event gets fired.
  dxFilterBuilder_onValueChange(changedValue) {
     this.filterValues = changedValue.component._model;
     this.filterValuesChange.emit(this.filterValues);
  }

  trackDemographicValues (index: number, item: ValueModelCheckBox ) {
    return item.id;
  }

  trackFilterVariables(index: number, item: VariableModel ) {
    return item.variableId;
  }
}

export class ValueModelCheckBox extends ValueModel {
  isChecked?: boolean;
}