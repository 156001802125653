import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import themes from "devextreme/ui/themes";
import { LookupModel } from 'src/app/core/models/lookup-model';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

  private _currentThemeSelectionPrefix: string;
  currentTheme: LookupModel;
  themes: LookupModel[] =
    [{
      id: 'generic.dark',
      name: marker('Generic Dark')
    },
    {
      id: 'generic.dark.compact',
      name: marker('Generic Dark Compact')
    },
    {
      id: 'generic.greenmist',
      name: marker('Generic Green Mist')
    },
    {
      id: 'generic.greenmist.compact',
      name: marker('Generic Green Mist Compact')
    },
    {
      id: 'generic.light',
      name: marker('Generic Light')
    },
    {
      id: 'generic.light.compact',
      name: marker('Generic Light Compact')
    },
    {
      id: 'material.blue.dark',
      name: marker('Material Dark')
    },
    {
      id: 'material.blue.dark.compact',
      name: marker('Material Dark Compact')
    },
    {
      id: 'material.blue.light',
      name: marker('Material Light')
    },
    {
      id: 'material.blue.light.compact',
      name: marker('Material Light Compact')
    },
    {
      id: 'material.lime.light',
      name: marker('Material Lime')
    },
    {
      id: 'material.lime.light.compact',
      name: marker('Material Lime Compact')
    }];


  constructor(private authService: AuthorizationService,  private globalService: AppConstantService, private translate: TranslateService) {

  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.themes.forEach((theme)=>{
        this.translate.get(theme.name).subscribe((result)=>{
          theme.name = result;
        })
      });
      /**reload saved themes prefix */
      this.currentTheme = JSON.parse(window.localStorage.getItem(this.globalService.localStorageThemeKeyName));
      if (this.currentTheme) {
        let id = this.currentTheme.id.toString();
        this._currentThemeSelectionPrefix = id.substring(0, id.indexOf('.'));
      }
    }
  }

  /**Saves the theme to local storage 
     * if the value of the first part of the theme name (separated by .)
     * then reload the page (requirement of devextreme)
    */
  onThemeChanged = (theme: LookupModel) => {
    let newTheme = theme.id + '';
    window.localStorage.setItem(this.globalService.localStorageThemeKeyName, JSON.stringify(theme));
    let themePrefix = newTheme.substring(0, newTheme.indexOf('.'));
    this.currentTheme = theme;
    if (themePrefix !== this._currentThemeSelectionPrefix) {
      this._currentThemeSelectionPrefix = themePrefix;
      window.location.reload();
    }else{
      themes.current(newTheme);
    }
  }

  /**will reset the theme to the default theme */
  resetTheme = () => {
    let defaultTheme = this.globalService.defaultTheme;
    this.onThemeChanged(defaultTheme);
  }
}
