import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { HttpClient } from '@angular/common/http';
import { AppConstantService } from 'src/app/core/services/app-constant.service';
import { CompanyModel } from '../../models/company/company-model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpResult } from '../../helpers/http-result';
import { CompanyLookupModel } from '../../models/company/company-lookup-model';
import { ApiResponse } from '../../models/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends HttpService {
  // companies: CompanyModel[];
  // companies$ = new BehaviorSubject<CompanyModel[]>(this.companies);

  // companyLookup: CompanyLookupModel;
  // companyLookup$ = new BehaviorSubject<CompanyLookupModel>(this.companyLookup);

  constructor(
    private http: HttpClient,
    private appConstant: AppConstantService
  ) {
    //super(http, appConstant);
    super();
  }

  addCompany(
    company: CompanyModel
  ): Observable<HttpResult<ApiResponse<CompanyModel>>> {
    return this.post<ApiResponse<CompanyModel>>(
      this.appConstant.apiPath.companies,
      company
    );
  }

  deleteCompany(
    company: any
  ): Observable<HttpResult<ApiResponse<CompanyModel>>> {
    return this.deleteByID<ApiResponse<CompanyModel>>(
      this.appConstant.apiPath.companies,
      company
    );
  }

  getCompanies(): Observable<HttpResult<ApiResponse<CompanyModel[]>>> {
    return this.get<ApiResponse<CompanyModel[]>>(
      this.appConstant.apiPath.companies
    );
  }

  getCompaniesLookup(): Observable<
    HttpResult<ApiResponse<CompanyLookupModel[]>>
  > {
    return this.get<ApiResponse<CompanyLookupModel[]>>(
      this.appConstant.apiPath.companies
    );
  }

  // setCompanyLookup(companies: CompanyLookupModel) {
  //   this.companyLookup$.next(companies);
  // }

  // setCompanies(companies: CompanyModel[]) {
  //   this.companies$.next(companies);
  // }

  updateCompany(
    company: CompanyModel
  ): Observable<HttpResult<ApiResponse<CompanyModel>>> {
    return this.put<ApiResponse<CompanyModel>>(
      this.appConstant.apiPath.companies,
      company
    );
  }
}
